import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { EditorState } from 'draft-js';
import Compressor from 'compressorjs';

import {
  mapStringOrPreviewFilePictureToStringPicture,
  PreviewFile,
} from 'src/types/content';
import { EditableContent } from '../../domain/content';
import { AdminOverviewService } from './overview';
import { DefaultPersonType, PersonType } from 'src/types/person';
import { TeamNetwork } from '../network/team/crud';

export class AdminTeamContentService {
  private _id: string;
  private _children?: AdminTeamChildContentService[];
  private _removed: string[] = [];

  get id(): string {
    return this._id;
  }

  get children(): AdminTeamChildContentService[] | undefined {
    return this._children;
  }

  get removed(): string[] {
    return this._removed;
  }

  private set children(value: AdminTeamChildContentService[] | undefined) {
    this._children = value ? [...value] : undefined;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
  }

  static fromEditableContent = (
    content: EditableContent<PersonType>
  ): AdminTeamContentService => {
    var service = new AdminTeamContentService(content.id);

    service.children = content.children?.map(
      AdminTeamChildContentService.fromEditableContent
    );

    return service;
  };

  addChild = () => {
    if (!this.children) this.children = [];
    var service = new AdminTeamChildContentService(uuidv4());
    service.updatePosition(this.children.length);

    this.children = [...this.children, service];

    AdminOverviewService.setSelected({ data: service, type: 'Team' });
  };

  reorder = (content: AdminTeamChildContentService, to: number) => {
    if (!this.children) return;
    const direction = content.position < to ? 'UP' : 'DOWN';
    this.children = [
      ...this.children.map((child) => {
        if (child.id === content.id) child.updatePosition(to);
        else if (direction === 'UP' && child.position <= to)
          child.updatePosition(child.position - 1);
        else if (direction === 'DOWN' && child.position >= to)
          child.updatePosition(child.position + 1);
        return child;
      }),
    ].sort((left, right) => (left.position > right.position ? -1 : 1));
  };

  removeChild = (id: string) => {
    if (!this.children) return;
    const removing = this.children.find((child) => child.id === id);
    if (!removing) return;

    this.removed.push(id);
    this.children = this.children.filter((child) => child.id !== removing?.id);

    if (AdminOverviewService.instance.selected?.data.id === id)
      AdminOverviewService.setSelected(undefined);
  };

  save = async () => {
    await Promise.all([
      TeamNetwork.update(this.children),
      TeamNetwork.delete(this.removed),
    ]);
  };
}

export class AdminTeamChildContentService {
  private _id: string;
  private _data?: PersonType;
  private _crop?: string | PreviewFile;
  private _parent: string[] = ['Team'];
  private _isNew: boolean = true;

  get id(): string {
    return this._id;
  }

  get data(): PersonType | undefined {
    return this._data;
  }

  get position(): number {
    return this.data?.position ?? 0;
  }

  get parent(): string[] {
    return this._parent;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  private set data(value: PersonType | undefined) {
    this._data = value;
  }

  get crop() {
    return this._crop;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
    this._data = {
      ...DefaultPersonType,
      id: id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  static fromEditableContent = (
    content: EditableContent<PersonType>
  ): AdminTeamChildContentService => {
    var service = new AdminTeamChildContentService(content.id);

    service.data = content.data;
    service._isNew = false;

    return service;
  };

  updateName = (value: string, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, name: value, updatedAt: new Date() };
  };

  updateInfo = (value: EditorState, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, information: value, updatedAt: new Date() };
  };

  updateFunction = (value: string, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, function: value, updatedAt: new Date() };
  };

  updatePosition = (value: number) => {
    if (!this.data) return;
    this.data = { ...this.data, position: value, updatedAt: new Date() };
  };

  updatePictureFromSelection = (img: File) => {
    new Compressor(img, {
      quality: 0.8,
      maxWidth: 1024,
      success: (compressed) => {
        var file: File;
        if (compressed instanceof File) {
          file = compressed;
        } else {
          file = new File([compressed], img.name);
        }

        if (!this.data) return;

        const urlCreator = window.URL || window.webkitURL;
        const preview: PreviewFile = {
          file: file,
          url: urlCreator.createObjectURL(file),
        };

        this.data = {
          ...this.data,
          picture: preview,
        };
      },
    });
  };

  updatePictureFromCrop = (img: File, _: string | PreviewFile) => {
    if (!this.data) return;

    const urlCreator = window.URL || window.webkitURL;
    const preview: PreviewFile = {
      file: img,
      url: urlCreator.createObjectURL(img),
    };

    this.data = {
      ...this.data,
      picture: preview,
      updatedAt: new Date(),
    };
  };

  removePicture = (img: string | PreviewFile) => {
    if (!this.data) return;

    this.data = {
      ...this.data,
      picture: undefined,
      updatedAt: new Date(),
    };
  };

  setCrop(img?: string | PreviewFile) {
    this._crop = img;
  }

  isUploaded = () => {
    runInAction(() => {
      this._isNew = false;
      if (this._data)
        this._data = {
          ...this._data,
          picture: this._data.picture
            ? mapStringOrPreviewFilePictureToStringPicture(this._data.picture)
            : undefined,
        };
    });
  };
}
