import React from 'react';
import { FirebaseImage } from '../image/firebase';
import { storage } from 'src/configuration';
import Skeleton from 'react-loading-skeleton';

interface AlternativePhotoSliderProps {
  id: string;
  images: string[];
}

export const AlternativePhotoSlider = ({
  id,
  images,
}: AlternativePhotoSliderProps) => {
  const [selected, setSelected] = React.useState(images[0]);

  return (
    <div className='alternativephotoslider'>
      <div className='alternativeimageselector'>
        {images.map((image, index) => (
          <FirebaseImage
            key={`alternativephotoslider-${index}`}
            img={image}
            storage={storage}
            className={
              selected === image
                ? 'alternativeselectableimage selected'
                : 'alternativeselectableimage'
            }
            storageprev={`Merchandise/${id}`}
            loader={<Skeleton className='alternativeselectableimage loading' />}
            onClick={setSelected}
          />
        ))}
      </div>
      <div className='alternativeimagedetails'>
        <FirebaseImage
          img={selected}
          storage={storage}
          className='alternativeselectedimage'
          storageprev={`Merchandise/${id}`}
          loader={<Skeleton className='alternativeselectedimage loading' />}
          zoomable
        />
      </div>
    </div>
  );
};
