import React from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import * as Components from 'src/administration/components';
import { FirebaseImage } from 'src/administration/components/image/firebase';
import { PreviewFile } from 'src/types/content';
import { AdminTeamChildContentService } from '../../../service/team';
import { ImageCrop } from '../../image/crop';
import { AdminOverviewService } from '../../../service/overview';
import { observer } from 'mobx-react-lite';
import { AddImage } from '../addimage';

import '../editable.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface TeamEditableProps {
  content: AdminTeamChildContentService;
}

export const TeamEditable = observer(({ content }: TeamEditableProps) => {
  const placeholder = React.useMemo(() => {
    if (content !== undefined) return 'Aktualisiere deinen Inhalt...';
    return 'Wählen Sie links den Inhalt, den Sie aktualisieren möchten...';
  }, [content]);

  const updateInfo = (value: EditorState) => {
    if (!content) return;
    content.updateInfo(value, true);
  };

  const crop = (img: string | PreviewFile) => {
    if (!content) return;
    content.setCrop(img);
  };

  return (
    <main className='admineditablecontainer'>
      <ImageCrop />
      <div className='admineditable'>
        <div className='admineditablecomponent'>
          <h3 className='editabletitle'>Name</h3>
          <Components.BasicInput
            id={`${content?.id}-title`}
            value={content?.data?.name ?? ''}
            onChange={content.updateName}
            className='editableinput'
            placeholder={placeholder}
            readonly={content === undefined}
            required
          />
        </div>
        <div className='admineditablecomponent'>
          <h3 className='editabletitle'>Funktion</h3>
          <Components.BasicInput
            id={`${content?.id}-function`}
            value={content?.data?.function ?? ''}
            onChange={content.updateFunction}
            className='editableinput'
            placeholder={placeholder}
            readonly={content === undefined}
            required
          />
        </div>
        <div className='admineditablecomponent'>
          <h3 className='editabletitle'>Informationen</h3>
          <Editor
            editorState={content?.data?.information}
            onEditorStateChange={updateInfo}
            placeholder={placeholder}
            wrapperClassName='admineditorwrapper'
            toolbarClassName='admintoolbar'
            editorClassName='admineditor'
          />
        </div>
        <div className='admineditablecomponent'>
          <h3 className='editabletitle'>Dein Bild</h3>
          <div className='editablepicturecontainer'>
            {content.data?.picture ? (
              <FirebaseImage
                storage={AdminOverviewService.instance.storage!}
                img={content.data?.picture}
                storageprev={`${content?.parent?.reduce(
                  (prev, curr) => prev + curr + '/',
                  ''
                )}${content?.id}`}
                withTasks
                onCrop={crop}
                onRemove={content.removePicture}
                canBeThumbnail={false}
              />
            ) : (
              <AddImage
                readonly={content === undefined}
                onImageAdd={content.updatePictureFromSelection}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
});
