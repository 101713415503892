import { FirebaseStorage } from 'firebase/storage';
import React from 'react';

import { PreviewFile } from 'src/types/content';
import { useFileRequest } from 'src/Network/fetchAPIs';
import './image.css';
import InnerImageZoom from 'react-inner-image-zoom';

interface FirebaseImageProps {
  storageprev?: string;
  img: string;
  storage: FirebaseStorage;
  className?: string;
  onClick?: (img: string) => void;
  loader?: JSX.Element;
  onLoaded?: (img: string) => void;
  zoomable?: boolean;
}

export const FirebaseImage = ({
  storageprev,
  img,
  storage,
  className,
  loader,
  onClick,
  onLoaded,
  zoomable,
}: FirebaseImageProps) => {
  const init = React.useRef(false);
  const previous = React.useRef<string | PreviewFile>('');
  const [file, setFile] = React.useState<string>('');
  const [loading, setLoading] = React.useState(true);
  const [fetch] = useFileRequest(storage, img, storageprev);

  React.useEffect(() => {
    if (init.current && img === previous.current) return;
    init.current = true;
    fetch()
      .then(setFile)
      .finally(() => {
        if (onLoaded) onLoaded(img);
        setLoading(false);
      });
    previous.current = img;
  }, [img]);

  const handleClick = () => onClick && onClick(img);

  if (loading)
    return (
      loader ?? (
        <div className={className ?? 'firebaseimgcontainer'}>
          <div className='firebaseimgloader' />
        </div>
      )
    );

  if (zoomable)
    return (
      <InnerImageZoom
        src={file}
        zoomSrc={file}
        zoomPreload
        zoomType='hover'
        imgAttributes={{ className: className }}
      />
    );

  return className ? (
    <img src={file} className={className} alt={file} onClick={handleClick} />
  ) : (
    <div className='firebaseimgcontainer' onClick={handleClick}>
      <img src={file} className='firebaseimg' alt={file} />
    </div>
  );
};
