import React from 'react';
import { FaBars, FaX } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import { AdminOverviewService } from '../../../service/overview';
import { AdminBookingChildContentService } from '../../../service/booking';

interface BookingNavigationSectionProps {
  id: string;
  content: AdminBookingChildContentService;
  onRemove?: (id: string) => void;
}

export const BookingChildNavigationSection = observer(
  ({ id, content, onRemove }: BookingNavigationSectionProps) => {
    const className = React.useMemo(() => {
      if (AdminOverviewService.instance.selected?.data?.id === id)
        return 'sectionheader selected';
      return 'sectionheader';
    }, [AdminOverviewService.instance.selected]);

    const select = () =>
      AdminOverviewService.setSelected({ data: content, type: 'Bookings' });

    const handleRemove = () => onRemove && onRemove(id);

    const header = React.useMemo(() => {
      if (!content.data) return content.id;
      if (
        content.data.start.format('DD.MM.YYYY') ===
          content.data.end.format('DD.MM.YYYY') ||
        content.data.start.isAfter(content.data.end)
      )
        return content.data.start.format('DD.MM.YYYY');

      return `${content.data.start.format(
        'DD.MM.YYYY'
      )} - ${content.data.end.format('DD.MM.YYYY')}`;
    }, [content.data]);

    return (
      <div id={id} className='navigationsection'>
        <div className='navigationcontent'>
          <div className='leftpart'>
            <FaBars fontSize={18} className='sectionicon' color='darkgray' />
            <h3 className={className} onClick={select}>
              {header}
            </h3>
          </div>
          <div className='rightpart'>
            <FaX
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={handleRemove}
            />
          </div>
        </div>
      </div>
    );
  }
);
