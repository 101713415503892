import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { BiSolidDownload } from 'react-icons/bi';

import { useWindowSize } from '../../Contracts/useWindowSize';
import { useFileRequest } from '../../Network/fetchAPIs';
import Skeleton from 'react-loading-skeleton';
import { AnalysisApi } from '../../Services/analysis/api';
import { AnalysisEvent } from '../../Services/analysis/domain/events';
import { storage } from 'src/configuration';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './index.css';

export const ClubStatutes = () => {
  const [numPages, setNumPages] = React.useState<number>();
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pdf, setPdf] = React.useState<string>();
  const [fetch] = useFileRequest(storage, 'Vereinssatzung.pdf', 'Dokumente');
  const [loading, setLoading] = React.useState(true);
  const { width } = useWindowSize();
  const init = React.useRef(false);

  const scale = React.useMemo(() => {
    if (width > 1000) return 0.85;
    if (width > 500) return 0.5;
    return 0.4;
  }, [width]);

  const leftDisabled = React.useMemo(() => {
    if (!numPages) return true;
    return pageNumber === 1;
  }, [pageNumber, numPages]);

  const rightDisabled = React.useMemo(() => {
    if (!numPages) return true;
    return pageNumber === numPages;
  }, [pageNumber, numPages]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setLoading(false);
  }

  const next = () => {
    if (!numPages) return;
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const prev = () => {
    if (!numPages) return;
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  function download() {
    if (!pdf) return;
    const a = document.createElement('a');
    a.href = pdf;
    a.download = 'Vereinssatzung.pdf';
    const clickHandler = () => {
      setTimeout(() => {
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  }

  React.useEffect(() => {
    if (pdf || init.current) return;

    init.current = true;
    setLoading(true);
    fetch()
      .then(setPdf)
      .catch((error) => {
        AnalysisApi.log(AnalysisEvent.ERROR, { error: `load pdf: ${error}` });
        setLoading(false);
      });
  }, []);

  return (
    <div className='content'>
      <h2>Vereinssatzung</h2>
      <div className='statutes'>
        {loading && <Skeleton className='statutesskeleton statutesdocument' />}
        {pdf && (
          <>
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              className={
                loading ? 'statutesdocument hidden' : 'statutesdocument'
              }
            >
              <Page
                pageNumber={pageNumber}
                className='statutespage'
                scale={scale}
                loading={
                  <Skeleton className='statutesskeleton statutesdocument' />
                }
              />
            </Document>
            <div className='separator' />
            <div className='statutescontrols'>
              <p className='downloadcontrol nonevisible' />
              <p className='pagecontrol'>
                <button onClick={prev} className='pagecontrolbutton'>
                  <FaChevronLeft
                    color={leftDisabled ? 'var(--Text25)' : 'var(--Text)'}
                    size={16}
                  />
                </button>
                <label className='pagecontrollabel'>{pageNumber}</label>
                <label className='pagecontrollabel'>von</label>
                <label className='pagecontrollabel'>{numPages}</label>
                <button onClick={next} className='pagecontrolbutton'>
                  <FaChevronRight
                    color={rightDisabled ? 'var(--Text25)' : 'var(--Text)'}
                    size={16}
                  />
                </button>
              </p>
              <button className='downloadcontrol' onClick={download}>
                <BiSolidDownload color='var(--Text)' size={16} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
