import React from 'react';

import './ui.css';

export const ErrorBoundaryView = () => {
  return (
    <main className='errorboundary'>
      <header className='errorboundaryheader'>
        <h1 className='errorboundaryheadercontent'>Das tut uns Leid! </h1>
        <h1 className='errorboundaryheadercontent'>
          Derzeit ist unsere Seite nicht verfügbar.
        </h1>
      </header>
      <footer className='errorboundaryfooter'>
        <p className='errorboundarydescription'>
          Wir kümmern uns bereits darum und bitten um eure Geduld, unsere
          Website steht euch bald wieder zur Verfügung.
        </p>
        <p className='errorboundarydescription'>
          Bei Anfrage oder Problemen könnt ihr uns gerne unter{' '}
          <a href='mailto:info@btg-manching.de'>info@btg-manching.de</a> an uns
          wenden.
        </p>
      </footer>
    </main>
  );
};
