import * as Auth from 'firebase/auth';
import { makeAutoObservable } from 'mobx';
import { auth } from 'src/configuration';

export class AuthorizationState {
  private user: Auth.User | null = null;

  private static instance = new AuthorizationState();

  constructor() {
    makeAutoObservable(this);
  }

  logIn = async () => {
    const provider = new Auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    try {
      const result = await Auth.signInWithPopup(auth, provider);
      this.user = result.user;
      return true;
    } catch (err) {
      return false;
    }
  };

  listen = (callback: () => void) => {
    Auth.onAuthStateChanged(auth, (user) => {
      this.user = user;
      callback();
    });
  };

  token = async () => {
    if (!this.user) return;
    return await this.user.getIdToken();
  };

  logOut = async () => {
    await Auth.signOut(auth);
    this.user = null;
  };

  static get isLoggedIn() {
    return AuthorizationState.instance.user !== null;
  }

  static logIn = () => AuthorizationState.instance.logIn();

  static listen = (callback: () => void) =>
    AuthorizationState.instance.listen(callback);

  static token = () => AuthorizationState.instance.token();

  static logOut = () => AuthorizationState.instance.logOut();
}
