import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  redirect: string;
  children: JSX.Element;
  redirectIf: boolean;
  forwardTo?: string;
  forwardToIf?: boolean;
}

export const ProtectedRoute = ({
  redirect,
  children,
  redirectIf,
  forwardTo,
  forwardToIf,
}: ProtectedRouteProps) => {
  if (redirectIf) return <Navigate to={redirect} replace />;

  if (forwardTo && forwardToIf) return <Navigate to={forwardTo} replace />;

  return children;
};
