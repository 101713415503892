import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';

import { DefaultPreviewContent, PreviewContent } from '../../types/content';
import { AnalysisApi } from '../../Services/analysis/api';
import { AnalysisEvent } from '../../Services/analysis/domain/events';

import { storage } from 'src/configuration';
import { getSpecificPost } from './application';
import * as Components from 'src/Components';

import './index.css';

export const DetailledPost = () => {
  const init = React.useRef(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = React.useState<PreviewContent>(
    DefaultPreviewContent
  );
  const [loading, setLoading] = React.useState(true);

  const contact = () => {
    AnalysisApi.log(AnalysisEvent.SELECT_CONTENT, {
      content_id: id,
      content_type: 'post',
    });
    navigate('/Kontakt');
  };

  React.useEffect(() => {
    if (!id || init.current) return;

    getSpecificPost(id.replace(/_/g, ' ')).then((preview) => {
      setLoading(false);
      init.current = true;
      if (preview) setContent(preview);
      else navigate('*');
    });
  });

  return (
    <div className='content post'>
      <Components.Article
        caller='Beiträge'
        loading={loading}
        content={content}
        additionalContent={
          <div className='thumbnailpicture'>
            {content.date && <div>{content.date}</div>}
            {content.thumbnailPicture && (
              <Components.FirebaseImage
                img={content.thumbnailPicture as string}
                storage={storage}
                storageprev={`Beiträge/${content.id}`}
                className='photoSliderImg showing headPicture'
                loader={<Skeleton className='thumbnailpictureloader' />}
              />
            )}
          </div>
        }
      />
      {content.date && (
        <Components.ButtonContainer
          information={'Interesse dabei zu sein?'}
          buttons={[
            {
              text: 'Hier anmelden',
              action: contact,
              primary: true,
            },
          ]}
        />
      )}
    </div>
  );
};
