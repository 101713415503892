import React from 'react';
import { BasicInput } from './basic';
import DatePicker from 'react-datepicker';

interface DateInputProps {
  id: string;
  value: string;
  selected?: Date;
  placeholder?: string;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  className: string;
}

export const DateInput = ({
  id,
  value,
  selected,
  minDate,
  onChange,
  placeholder,
  className,
}: DateInputProps) => {
  const picker = React.useRef<DatePicker | null>(null);

  const setRef = (ref: DatePicker | null) => {
    picker.current = ref;
  };

  const open = () => {
    if (!picker.current) return;
    picker.current.toggleCalendar();
  };

  return (
    <>
      <BasicInput
        id={id}
        value={value}
        className={className}
        placeholder={placeholder}
        readonly
        required
        onClick={open}
      />
      <DatePicker
        ref={setRef}
        selected={selected}
        onChange={onChange}
        onSelect={onChange}
        dateFormat={'dd.MM.YYYY'}
        className='picker'
        minDate={minDate}
        withPortal
      />
    </>
  );
};
