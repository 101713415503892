import React from 'react';

import * as Components from 'src/Components';
import { ContactService } from 'src/Pages/Contact/application';
import { observer } from 'mobx-react-lite';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './index.css';

export const ContactForm = observer(() => {
  const [key, setKey] = React.useState(new Date());

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = async () => {
    if (!executeRecaptcha) return false;
    const gRecaptchaToken = await executeRecaptcha('contactsubmit');
    var valid = await ContactService.verifyRecaptcha(gRecaptchaToken);
    if (!valid) return false;
    var success = await ContactService.submit();
    setKey(new Date());
    return success;
  };

  React.useEffect(() => {
    return () => ContactService.reset();
  }, []);

  return (
    <div className='form'>
      <Components.BasicInput
        key={`${key.toISOString()}-name`}
        className='formInput c2'
        value={ContactService.instance.name.text}
        onChange={ContactService.handleNameChange}
        regexp={ContactService.instance.nameRegexp}
        placeholder='Name'
      />
      <Components.BasicInput
        key={`${key.toISOString()}-mail`}
        className='formInput c2'
        value={ContactService.instance.mail.text}
        onChange={ContactService.handleMailChange}
        regexp={ContactService.instance.mailRegexp}
        placeholder='E-Mail'
        type='email'
      />
      <Components.BasicInput
        key={`${key.toISOString()}-subject`}
        className='formInput c2'
        value={ContactService.instance.subject.text}
        onChange={ContactService.handleSubjectChange}
        regexp={ContactService.instance.subjectRegexp}
        placeholder='Betreff'
      />
      <Components.MultilineInput
        id='contact-textarea-content'
        key={`${key.toISOString()}-multiline`}
        className='formInput c2 area'
        value={ContactService.instance.content.text}
        onChange={ContactService.handleContentChange}
        regexp={ContactService.instance.contentRegexp}
        placeholder='Was möchtest du uns schreiben?'
        rows={5}
      />
      <Components.SubmitButton
        label='Senden'
        disabled={!ContactService.instance.valid}
        onSubmit={submit}
        className='actionButton contactButton'
      />
      <p className='contactsecuritydescription c4'>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href='https://policies.google.com/privacy'>Privacy Policy</a> and{' '}
        <a href='https://policies.google.com/terms'>Terms of Service</a> apply.
      </p>
    </div>
  );
});
