import React from 'react';
import { FaPlus } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import { AdminMerchandiseContentService } from '../../../service/merchandise';
import { MerchandiseChildNavigationSection } from './child';

interface MerchandiseNavigationSectionProps {
  id: string;
  content: AdminMerchandiseContentService;
}

export const MerchandiseNavigationSection = observer(
  ({ id, content }: MerchandiseNavigationSectionProps) => {
    return (
      <div id={id} className='navigationsection'>
        <div className='navigationcontent'>
          <div className='leftpart'>
            <h3 className='sectionheader'>{content.id}</h3>
          </div>
          <div className='rightpart'>
            <FaPlus
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={content.addChild}
            />
          </div>
        </div>
        <div className='navigationchildren'>
          {content.children?.slice().map((child) => (
            <MerchandiseChildNavigationSection
              id={child.data?.id ?? child.id}
              key={child.data?.id ?? child.id}
              content={child}
              onRemove={content.removeChild}
              onReorder={content.reorder}
            />
          ))}
        </div>
      </div>
    );
  }
);
