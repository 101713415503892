import React from 'react';
import { MemberContainer } from '../../Components/MemberContainer';

export const TeamPage = () => {
  return (
    <div className='content'>
      <MemberContainer caller={'Vorstand'} />
    </div>
  );
};
