import moment from 'moment';

export const isBeforeDay = (check: moment.Moment, reference: moment.Moment) => {
  if (check.year() > reference.year()) return false;
  if (check.month() > reference.month() && check.year() === reference.year())
    return false;
  if (
    check.date() >= reference.date() &&
    check.month() === reference.month() &&
    check.year() === reference.year()
  )
    return false;
  return true;
};

export const isAfterDay = (check: moment.Moment, reference: moment.Moment) => {
  if (check.year() < reference.year()) return false;
  if (check.month() < reference.month() && check.year() === reference.year())
    return false;
  if (
    check.date() <= reference.date() &&
    check.month() === reference.month() &&
    check.year() === reference.year()
  )
    return false;
  return true;
};

export const isSameDay = (check: moment.Moment, reference: moment.Moment) =>
  check.year() === reference.year() &&
  check.month() === reference.month() &&
  check.date() === reference.date();

export const isInRange = (
  check: moment.Moment,
  from: moment.Moment,
  to: moment.Moment
) => {
  if (isBeforeDay(check, from)) return false;
  if (isAfterDay(check, to)) return false;
  return true;
};
