import React, { useState } from 'react';
import { Menu } from './Menu';
import { Link } from 'react-router-dom';

import './index.css';

type Props = { pages: string[] };

export const TopBar = ({ pages }: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggle = () => setShowMenu((prev) => !prev);

  return (
    <div className='topBarWrapper'>
      <div className='topBar'>
        <Link to='/' className='homeIcon noLinkDecoration'>
          <img className='homeIcon' src='/BTG_Final.png' alt='BtgFinal' />
        </Link>
        <div
          className={`centerFlex menuMobile ${showMenu ? 'showMenu' : ''}`}
          onClick={toggle}
        >
          <div className='menuBarAll menuBarBig' id='menuBar1' />
          <div className='menuBarAll' id='menuBar2' />
          <div className='menuBarAll menuBarBig' id='menuBar3' />
        </div>
      </div>
      <Menu
        pages={pages.filter((page) => page !== 'Home')}
        showMenu={showMenu}
        onClick={toggle}
      />
    </div>
  );
};
