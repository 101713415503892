import axios from 'axios';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  FirebaseMerchandise,
  mapFirebaseMerchToMerch,
  Merchandise,
} from '../../domain/merchandise';

export const getMerchandise = async (): Promise<Merchandise[]> => {
  try {
    const response = await axios.get<FirebaseMerchandise[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/merchandise'
    );

    const data = response.data.map(mapFirebaseMerchToMerch);

    return data.sort((left, right) =>
      left.position > right.position ? -1 : 1
    );
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, { error: `getMerchandise: ${error}` });
    return [];
  }
};

export const getSpecificMerchandise = async (
  name: string
): Promise<Merchandise | undefined> => {
  try {
    const response = await axios.get<FirebaseMerchandise>(
      `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/merchandise/search/${name}`
    );

    const data = mapFirebaseMerchToMerch(response.data);

    return data;
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, {
      error: `getSpecificMerchandise: ${error}`,
    });
    return undefined;
  }
};
