import React from 'react';
import { FaBars, FaX } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';

import { AdminTeamChildContentService } from '../../../service/team';
import { AdminOverviewService } from '../../../service/overview';

interface TeamNavigationSectionProps {
  id: string;
  content: AdminTeamChildContentService;
  onRemove?: (id: string) => void;
  onReorder: (content: AdminTeamChildContentService, to: number) => void;
}

interface DropResult {
  element: AdminTeamChildContentService;
}

interface DragObject {
  isOver: boolean;
}

export const TeamChildNavigationSection = observer(
  ({ id, content, onRemove, onReorder }: TeamNavigationSectionProps) => {
    const className = React.useMemo(() => {
      if (AdminOverviewService.instance.selected?.data?.id === id)
        return 'sectionheader selected';
      return 'sectionheader';
    }, [AdminOverviewService.instance.selected]);

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: 'TEAM',
        drop: () => ({
          element: content,
          allowedDropEffect: true,
        }),
        collect: (monitor: DropTargetMonitor<DragObject, DropResult>) => ({
          isOver: monitor.isOver(),
        }),
      }),
      []
    );

    const [{ opacity }, drag] = useDrag(() => ({
      type: 'TEAM',
      item: content,
      end: (
        item: AdminTeamChildContentService,
        monitor: DragSourceMonitor<AdminTeamChildContentService, DropResult>
      ) => {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          onReorder(item, dropResult.element.position);
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }));

    const select = () =>
      AdminOverviewService.setSelected({ data: content, type: 'Team' });

    const handleRemove = () => onRemove && onRemove(id);

    const dropopacity = React.useMemo(() => {
      if (opacity !== 1.0) return 1.0;
      if (isOver) return 0.4;
      return 1.0;
    }, [isOver, opacity]);

    return (
      <div id={id} ref={drop} className='navigationsection'>
        <div className='navigationcontent'>
          <div style={{ opacity: dropopacity }}>
            <div ref={drag} style={{ opacity }} className='leftpart'>
              <FaBars fontSize={18} className='sectionicon' color='darkgray' />
              <h3 className={className} onClick={select}>
                {content.data?.name ?? content.id}
              </h3>
            </div>
          </div>
          <div className='rightpart'>
            <FaX
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={handleRemove}
            />
          </div>
        </div>
      </div>
    );
  }
);
