import React from 'react';

import './index.css';

export const Impressum = () => {
  return (
    <div className='content'>
      <div className='impressum alignment'>
        <h2>Impressum</h2>
        <div>
          <p className='c2'>
            Betreuungs- & Traditionsgemeinschaft Manching e.V.
          </p>
          <p className='c2'>Rechliner Str.</p>
          <p className='c2'>85077 Manching</p>
        </div>
        <h3>Kontakt</h3>
        <div>
          <p className='c2'>
            <a href='tel:+4984598113301'>Telefon: +49 8459 81 13301</a>
          </p>
          <p className='c2'>
            <a href='mailTo:info@btg-manching.de'>
              E-Mail: info@btg-manching.de
            </a>
          </p>
        </div>
        <h3>Allgemeine Vertretungsregelung</h3>
        <p className='c2'>
          Jeweils zwei Vorstandsmitglieder vertreten den Verein gemeinsam.
        </p>
        <h3>Vertretungsberechtigte und besondere Vertretungsbefugnis</h3>
        <div>
          <p className='c2'>Vorstand: Becker, Frank</p>
          <p className='c2'>Vorstand: Geisler, Christian</p>
          <p className='c2'>Vorstand: Schäffler, Christian</p>
        </div>
        <h3>Registergericht und Registernummer</h3>
        <div>
          <p className='c2'>Vereinsregister des Amtsgerichts München</p>
          <p className='c2'>VR 110110</p>
        </div>
        <h3>Inhaltlich Verantwortlicher gemäß §18 Abs. 2 MStV</h3>
        <div>
          <p className='c2'>Christian Geisler</p>
          <p className='c2'>Rechliner Str.</p>
          <p className='c2'>85077 Manching</p>
        </div>
        <h3>EU-Streitschlichtung</h3>
        <p className='c2'>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          https://ec.europa.eu/consumers/odr/. Unsere E-Mail Adresse finden Sie
          oben im Impressum.
        </p>
        <h3>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h3>
        <p className='c2'>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
    </div>
  );
};
