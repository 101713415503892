import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  Buchung,
  FirebaseBuchung,
  mapFirebaseBuchungToBuchung,
} from '../../domain/buchung';
import axios from 'axios';

export const getBookings = async (): Promise<Buchung[]> => {
  try {
    const response = await axios.get<FirebaseBuchung[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/bookings'
    );

    const data = response.data.map(mapFirebaseBuchungToBuchung);

    return data;
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, { error: `getBookings: ${error}` });
    return [];
  }
};
