import React from 'react';
import { Merchandise } from '../../domain/merchandise';
import { SubmitButton } from 'src/Components';
import { useNavigate } from 'react-router-dom';
import { FirebaseImage } from 'src/Components/image/firebase';
import { storage } from 'src/configuration';
import Skeleton from 'react-loading-skeleton';
import { convertToRaw } from 'draft-js';
import { generateSizeText } from '../helper/extension';

interface MerchProps {
  merchandise: Merchandise;
  loading: boolean;
}

export const Merch = ({ merchandise, loading }: MerchProps) => {
  const requestAvailable = React.useRef(false).current;
  const navigate = useNavigate();

  const contact = () => {
    var supportEmail = 'info@btg-manching.de';
    var formattedBody = `Guten Tag, \n\ngerne möchte ich den folgenden Artikel bei Ihnen bestellen. \n\n    Mitgliedsnummer: \n    Artikel: ${merchandise.name} \n    Anzahl: \n    Lieferadresse: \n\nMit freundlichen Grüßen,\n\n`;
    window.location.href = `mailto:${supportEmail}?subject=Bestellung ${encodeURIComponent(
      merchandise.name
    )}&body=${encodeURIComponent(formattedBody)}`;

    return true;
  };

  const showDetails = () => {
    navigate(`/Merchandise/${merchandise.name.replace(/ /g, '_')}`);
  };

  const description = React.useMemo(() => {
    var first = convertToRaw(merchandise.description.getCurrentContent())
      .blocks[0].text;
    return first;
  }, [merchandise]);

  return (
    <div className={loading ? 'merch full' : 'merch'} onClick={showDetails}>
      {loading ? (
        <Skeleton className='merchskeleton' />
      ) : (
        <>
          <FirebaseImage
            img={merchandise.pictures[0] as string}
            storage={storage}
            className='merchimage'
            storageprev={`Merchandise/${merchandise.id}`}
            loader={<Skeleton className='merchimageloader' />}
          />
          <div className='merchinfos'>
            <div className='merchtop'>
              <h4 className='merchname'>{merchandise.name}</h4>
              {description !== '' ? (
                <p className='merchdescription c2'>{description}</p>
              ) : (
                <p style={{ visibility: 'hidden' }}>P</p>
              )}
              {generateSizeText(merchandise) !== '' ? (
                <p className='merchsize c2'>{generateSizeText(merchandise)}</p>
              ) : (
                <p style={{ visibility: 'hidden' }}>P</p>
              )}
            </div>
            <div className='merchbottom'>
              <div className='merchbottominfos'>
                <div
                  className={
                    merchandise.available
                      ? 'merchstatus available'
                      : 'merchstatus'
                  }
                />
                <p className='c1'>{merchandise.costs}€*</p>
              </div>
              <div className='merchbutton'>
                <SubmitButton
                  label='Anfragen'
                  onSubmit={contact}
                  disabled={!requestAvailable || !merchandise.available}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
