import React from 'react';

import './index.css';

export const Datenschutz = () => {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://consent.cookiebot.com/4fd941ab-2253-4675-b4d8-4b21277ee460/cd.js';
    script.type = 'text/javascript';
    script.id = 'CookieDeclaration';
    script.async = true;

    const element = document.getElementById('cookiedeclaration');

    element?.appendChild(script);
  }, []);

  return (
    <div className='content'>
      <div className='datenschutz alignment'>
        <h2>Datenschutz</h2>
        <h3>Allgemeine Hinweise</h3>
        <p className='c2'>
          Diese Datenschutzerklärung enthält ausführliche Informationen darüber,
          was mit Ihren persönlichen Daten passiert, wenn Sie unsere Website
          www.btg-manching.de besuchen. Personenbezogene Daten sind alle Daten,
          mit denen Sie sich persönlich identifizieren können. Wir halten uns
          bei der Verarbeitung Ihrer Daten streng an die gesetzlichen
          Bestimmungen, insbesondere die Datenschutz-grundverordnung(„DSGVO“),
          und legen großen Wert darauf, dass Ihr Besuch auf unserer Website
          absolut sicher ist.
        </p>
        <h3>Verantwortliche Stelle</h3>
        <div>
          <p className='c2'>
            Datenschutzrechtlich verantwortlich für die Erhebung und
            Verarbeitung von personenbezogenen Daten auf dieser Website ist:
          </p>
          <p className='c2'>Name: BTG Manching e. V.</p>
          <p className='c2'>Vertreten durch: Christian Geisler, Vorsitzender</p>
          <p className='c2'>Straße, Hausnummer: Rechliner Str.</p>
          <p className='c2'>Postleitzahl, Ort: 85077 Manching</p>
          <p className='c2'>Land: Deutschland</p>
          <p className='c2'>E-Mail: info@btg-manching.de</p>
        </div>
        <h3>Zugriffsdaten (Server-Logfiles)</h3>
        <div>
          <p className='c2'>
            Beim Aufruf unserer Website erheben wir und speichern automatisch in
            sogenannten Server-Logfiles Zugriffsdaten, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <br />
          <p className='c2'>
            <ul>
              <li className='c2'>Browsertyp und Browserversion Ihres PC</li>
              <li className='c2'>von Ihrem PC verwendetes Betriebssystem</li>
              <li className='c2'>
                Referrer URL (Quelle/Verweis, von welchem Sie auf unsere Website
                gelangten)
              </li>
              <li className='c2'>Hostname des zugreifenden Rechners </li>
              <li className='c2'>Datum und Uhrzeit der Serveranfrage</li>
              <li className='c2'>
                die aktuell von Ihrem PC verwendete IP-Adresse (ggf. in
                anonymisierter Form)
              </li>
              <li className='c2'>Nutzerdaten</li>
            </ul>
          </p>
          <br />
          <p className='c2'>
            Ein Personenbezug ist uns im Regelfall nicht möglich und auch nicht
            beabsichtigt. Die Verarbeitung solcher Daten erfolgt gemäß Art. 6
            Abs. 1 lit. f DSGVO zur Wahrung unseres berechtigten Interesses an
            der Verbesserung der Stabilität und Funktionalität unserer Website.
          </p>
        </div>
        <h3>Cookies</h3>
        <div>
          <p className='c2'>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu ermöglichen, verwenden wir sogenannte
            Cookies. Hierbei handelt es sich um kleine Textdateien, die auf
            Ihrem Endgerät abgelegt werden. Cookies können keine Programme
            ausführen oder Viren auf Ihr Computersystem übertragen.
          </p>
          <p className='c2'>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen erforderlich sind, werden auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir haben ein berechtigtes
            Interesse an der Speicherung von Cookies zur technisch fehlerfreien
            und optimierten Bereitstellung unserer Dienste. Soweit andere
            Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert
            werden, werden diese in dieser Datenschutzerklärung gesondert
            behandelt.
          </p>
          <p className='c2'>
            Die meisten der von uns verwendeten Cookies sind so genannte
            „Session-Cookies". Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.
          </p>
          <p className='c2'>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
        </div>
        <h3>§1 Webanalyse Tools und Werbung</h3>
        <h4>1.1 Google Analytics</h4>
        <div>
          <p className='c2'>
            Unsere Website benutzt den Webanalysedienst Google Analytics in der
            Version Google Analytics 4. Anbieter ist die Google Ireland Ltd.
            Gordon House, Barrow Street, Dublin 4, Ireland („Google“).
          </p>
          <p className='c2'>
            Google Analytics verwendet sogenannte „Cookies". Das sind
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung unserer Website durch Sie ermöglichen. In
            Google Analytics werden alle Daten von Geräten, die sich in der EU
            befinden (basierend auf dem geografischen Standort laut IP-Adresse),
            über Domains und Server in der EU erhoben, bevor der Traffic zur
            Verarbeitung an Analytics-Server weitergeleitet wird.
          </p>
          <p className='c2'>
            Rechtsgrundlage für die Verarbeitung Ihrer Daten ist die von Ihnen
            über das Cookie-Consent-Tool erteilte Einwilligung gemäß Art. 6 Abs.
            1 Satz 1 lit. a) DSGVO
          </p>
        </div>
        <h4>a) IP-Anonymisierung</h4>
        <p className='c2'>
          Bei Google Analytics ist die Funktion IP-Anonymisierung auf der
          Website automatisch aktiviert. Dadurch wird Ihre IP-Adresse von Google
          innerhalb von Mitgliedstaaten der EU oder in anderen Vertragsstaaten
          des Abkommens über den Europäischen Wirtschaftsraum vor der
          Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort
          gekürzt. In unserem Auftrag wird Google diese Informationen benutzen,
          um Ihre Nutzung unserer Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber uns zu erbringen. Gemäß Google werden in Google Analytics
          keine IP-Adressen protokoliert und gespeichert, sondern lediglich
          kurzzeitig für die Geo-Lokalisierung verarbeitet und sofort danach
          gelöscht. Die im Rahmen von Google Analytics von Ihrem Browser
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt.
        </p>
        <h4>Auftragsverarbeitung</h4>
        <p className='c2'>
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
          abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
          um.
        </p>
        <h4>Speicherdauer</h4>
        <p className='c2'>
          Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
          Cookies, Nutzerkennungen (z.B. User ID) oder Werbe-IDs (z.B.
          DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 2
          Monaten gelöscht. Details hierzu ersehen Sie unter folgendem Link:
          https://support.google.com/ analytics/answer/7667196?hl=de.
        </p>
        <h4>Widerrufsrecht</h4>
        <div>
          <p className='c2'>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sofern die Verarbeitung Ihrer Daten auf Ihrer
            Einwilligung beruht, haben Sie das Recht, eine einmal erteilte
            Einwilligung in die Verarbeitung von Daten gemäß Art. 7 Abs. 3 DSGVO
            jederzeit mit Wirkung für die Zukunft zu widerrufen, indem Sie die
            Cookie-Einstellungen aufrufen und dort Ihre Auswahl ändern. Durch
            den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
            der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
            berührt. Speicherung der Daten für Abrechnungs- und buchhalterische
            Zwecke bleibt von einem Widerruf nicht berührt.
          </p>
          <p className='c2'>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
            finden Sie in der Datenschutzerklärung von Google:
            https://support.google.com/ analytics/answer/6004245?hl=de.
          </p>
          <p className='c2'>
            Weitere Informationen zum Datenschutz können Sie der
            Datenschutzerklärung von Google entnehmen:
            https://policies.google.com/privacy?hl=de&gl=de
          </p>
        </div>
        <h4>1.2 Google Tag Manager</h4>
        <p className='c2'>
          Unsere Website verwendet den Google Tag Manager. Anbieter ist die
          Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland
          („Google"). Google Tag Manager ist eine Lösung, mit der Vermarkter
          Website-Tags über eine Oberfläche verwalten können. Das Tool, das die
          Tags implementiert, ist eine Cookie-lose Domain und speichert keine
          personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer
          Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
          Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
          Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
          alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert
          werden.
        </p>
        <h4>Google Maps</h4>
        <div>
          <p className='c2'>
            Unsere Website nutzt über eine API (Application Programming
            Interface) den Kartendienst Google Maps des Anbieters Google.
          </p>
          <p className='c2'>
            Um den Datenschutz auf unserer Website zu gewährleisten, ist Google
            Maps deaktiviert, wenn Sie unsere Website das erste Mal betreten.
            Eine direkte Verbindung zu den Servern von Google wird erst
            hergestellt, wenn Sie Google Maps selbstständig aktivieren
            (Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO). Auf diese Weise wird
            verhindert, dass Ihre Daten schon beim ersten Betreten unserer
            Website an Google übertragen werden.
          </p>
          <p className='c2'>
            Nach der Aktivierung wird Google Maps Ihre IP-Adresse speichern.
            Diese wird anschließend in der Regel an einen Server von Google in
            den USA übertragen und dort gespeichert. Der Anbieter dieser Seite
            hat nach der Aktivierung von Google Maps keinen Einfluss auf diese
            Datenübertragung.
          </p>
          <p className='c2'>
            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Google: https://www.google.de/
            intl/de/policies/privacy/.
          </p>
        </div>
        <h3>Kontaktformular</h3>
        <div>
          <p className='c2'>
            Sofern Sie mit uns Kontakt per E-Mail oder über ein Kontaktformular
            aufnehmen, werden übermittelte Daten einschließlich Ihrer
            Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder
            um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
            findet ohne Ihre Einwilligung nicht statt.
          </p>
          <p className='c2'>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs.
            1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung
            ist jederzeit möglich. Für den Widerruf genügt eine formlose
            Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p className='c2'>
            Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder keine Notwendigkeit der Datenspeicherung mehr
            besteht. Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </p>
        </div>
        <h3>Datenverwendung und -weitergabe</h3>
        <div>
          <p className='c2'>
            Die personenbezogenen Daten, die Sie uns z.B. per E-Mail mitteilen
            (z.B. Ihr Name und Ihre Adresse oder Ihre E-Mail-Adresse), werden
            wir weder an Dritte verkaufen noch anderweitig vermarkten. Ihre
            personenbezogenen Daten werden nur zur Korrespondenz mit Ihnen und
            nur für den Zweck verarbeitet, zu dem Sie uns die Daten zur
            Verfügung gestellt haben. Zur Abwicklung von Zahlungen geben wir
            Ihre Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut
            weiter.
          </p>
          <p className='c2'>
            Die Verwendung von Daten, die bei Ihrem Besuch unserer Website
            automatisch erhoben werden, erfolgt nur zu den vorstehend genannten
            Zwecken. Eine anderweitige Verwendung der Daten findet nicht statt.
          </p>
          <p className='c2'>
            Wir versichern, dass wir Ihre personenbezogenen Daten im Übrigen
            nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich
            verpflichtet wären oder Sie uns vorher Ihre Zustimmung gegeben
            haben.
          </p>
        </div>
        <h3>SSL- bzw- TLS-Verschlüsselung</h3>
        <div>
          <p className='c2'>
            Unsere Website benutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die
            Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
            daran, dass die Adresszeile des Browsers von „http://" auf
            „https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p className='c2'>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
        </div>
        <h3>§2 Speicherdauer</h3>
        <p className='c2'>
          Personenbezogene Daten, die uns über unsere Website mitgeteilt worden
          sind, werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu
          dem sie uns anvertraut wurden. Soweit handels- und steuerrechtliche
          Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu
          bestimmten Daten bis zu 10 Jahre betragen.
        </p>
        <h3>§3 Betroffenenrechte</h3>
        <p className='c2'>
          Hinsichtlich der Sie betreffenden personenbezogenen Daten haben als
          Betroffener der Datenverarbeitung nach Maßgabe der gesetzlichen
          Bestimmungen folgende Rechte gegenüber dem Verantwortlichen:
        </p>
        <h4>3.1 Widerrufsrecht</h4>
        <p className='c2'>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sofern die Verarbeitung Ihrer Daten auf Ihrer
          Einwilligung beruht, haben Sie das Recht, eine einmal erteilte
          Einwilligung in die Verarbeitung von Daten gemäß Art. 7 Abs. 3 DSGVO
          jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den
          Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
          Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          Speicherung der Daten für Abrechnungs- und buchhalterische Zwecke
          bleibt von einem Widerruf nicht berührt.
        </p>
        <h4>3.2 Auskunftsrecht</h4>
        <p className='c2'>
          Sie haben das Recht, gemäß Art. 15 DSGVO von uns eine Bestätigung
          darüber zu verlangen, ob wir personenbezogene Daten verarbeiten, die
          Sie betreffen. Liegt eine solche Verarbeitung vor, haben Sie das Recht
          auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten,
          die Verarbeitungszwecke, die Kategorien der verarbeiteten
          personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
          gegenüber denen Ihre Daten offengelegt wurden oder werden, die
          geplante Speicherdauer bzw. die Kriterien für die Festlegung der
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
          Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung,
          Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn
          diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer
          automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftige Informationen über die involvierte Logik und die Sie
          betreffende Tragweite und die angestrebten Auswirkungen einer solchen
          Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien
          gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
          bestehen.
        </p>
        <h4>3.3 Recht auf Berichtigung</h4>
        <p className='c2'>
          Sie haben das Recht, gemäß Art. 16 DSGVO jederzeit unverzügliche
          Berichtigung Sie betreffender unrichtiger personenbezogener Daten
          und/oder die Vervollständigung Ihrer unvollständigen Daten zu
          verlangen.
        </p>
        <h4>3.4 Recht auf Löschung</h4>
        <div>
          <p className='c2'>
            Sie haben das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer
            personenbezogenen Daten zu verlangen, sofern einer der folgenden
            Gründe zutrifft:
          </p>
          <br />
          <ul>
            <li className='c2'>
              Ihre personenbezogenen Daten sind für die Zwecke, für die sie
              erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
              notwendig.
            </li>
            <li className='c2'>
              Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
              gemäß Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO
              stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
              die Verarbeitung.
            </li>
            <li className='c2'>
              Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die
              Verarbeitung ein und es liegen keine vorrangigen berechtigten
              Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs.
              2 DSGVO Widerspruch gegen die Verarbeitung ein.
            </li>
            <li className='c2'>
              Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
            </li>
            <li className='c2'>
              Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
              rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht des
              Mitgliedstaates erforderlich, dem wir unterliegen.
            </li>
            <li className='c2'>
              Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
              der Informationsgesellschaft gemäß Art. 8 Abs. DSGVO erhoben.
            </li>
          </ul>
          <br />
          <p className='c2'>
            Dieses Recht besteht jedoch nicht, soweit die Verarbeitung
            erforderlich ist:
          </p>
          <br />
          <ul>
            <li className='c2'>
              zur Ausübung des Rechts auf freie Meinungsäußerung und
              Information;
            </li>
            <li className='c2'>
              zur Erfüllung einer rechtlichen Verpflichtung, die die
              Verarbeitung nach dem Recht der Union oder des Mitgliedstaates,
              dem wir unterliegen, erfordert, oder zur Wahrnehmung einer
              Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
              öffentlicher Gewalt erfolgt, die uns übertragen wurde;
            </li>
            <li className='c2'>
              aus Gründen des öffentlichen Interesses im Bereich der
              öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
              Art. 9 Abs 3 DSGVO;
            </li>
            <li className='c2'>
              für im öffentlichen Interesse liegende Archivzwecke,
              wissenschaftliche oder historische Forschungszwecke oder für
              statistische Zwecke gemäß Art. 89 Abs. 1 DSGVO, soweit das
              Betroffenenrecht voraussichtlich die Verwirklichung der Ziele
              dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt,
              oder
            </li>
            <li className='c2'>
              zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen.
            </li>
          </ul>
          <br />
          <p className='c2'>
            Haben wir Ihre personenbezogenen Daten öffentlich gemacht und sind
            wir nach Vorstehendem zu deren Löschung verpflichtet, so treffen wir
            unter Berücksichtigung der verfügbaren Technologie und der
            Implementierungskosten angemessene Maßnahmen, auch technischer Art,
            um für die Datenverarbeitung Verantwortliche, die die
            personenbezogenen Daten verarbeiten, darüber zu informieren, dass
            Sie als betroffene Person von ihnen die Löschung aller Links zu
            Ihren personenbezogenen Daten oder von Kopien oder Replikationen
            dieser personenbezogenen Daten verlangt haben.
          </p>
        </div>
        <h4>3.5 Recht auf Einschränkung der Verarbeitung</h4>
        <div>
          <p className='c2'>
            Sie haben das Recht, gemäß Art. 18 DSGVO die Einschränkung der
            Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu verlangen.
            Hierzu können Sie sich jederzeit unter der im Impressum angegebenen
            Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung
            besteht in folgenden Fällen:
          </p>
          <br />
          <ul>
            <li className='c2'>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li className='c2'>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah / geschieht, können Sie statt der Löschung die
              Einschränkung der Datenverarbeitung verlangen.
            </li>
            <li className='c2'>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li className='c2'>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <br />
          <p className='c2'>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten - von ihrer Speicherung
            abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der EU oder eines
            Mitgliedstaats verarbeitet werden.
          </p>
        </div>
        <h4>3.6 Recht auf Unterrichtung</h4>
        <p className='c2'>
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
          Verarbeitung gegenüber uns geltend gemacht, sind wir verpflichtet,
          allen Empfängern, denen Ihre personenbezogenen Daten offengelegt
          wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung
          der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
          unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
          Ihnen steht gemäß Art. 19 DSGVO das Recht zu, nach Verlangen über
          diese Empfänger unterrichtet zu werden.
        </p>
        <h4>
          3.7 Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung - einschließlich Profiling - beruhenden Entscheidung
          unterworfen zu werden
        </h4>
        <div>
          <p className='c2'>
            Sie haben das Recht, gemäß Art. 22 DSGVO nicht einer ausschließlich
            auf einer automatisierten Verarbeitung - einschließlich Profiling
            beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
            rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt.
          </p>
          <p className='c2'>Dies gilt nicht, wenn die Entscheidung</p>
          <br />
          <ul>
            <li className='c2'>
              für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
              und uns erforderlich ist,
            </li>
            <li className='c2'>
              aufgrund von Rechtsvorschriften der Union oder der
              Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
              ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
              Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen
              enthalten oder
            </li>
            <li className='c2'>
              mit Ihrer ausdrücklichen Einwilligung erfolgt.
            </li>
          </ul>
          <br />
          <p className='c2'>
            Allerdings dürfen die Entscheidungen in den in (a) bis (c) genannten
            Fällen nicht auf besonderen Kategorien personenbezogener Daten nach
            Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder
            lit. g gilt und angemessene Maßnahmen zum Schutz der Rechte und
            Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
          </p>
          <p className='c2'>
            In den in (a) und (c) genannten Fällen treffen wir angemessene
            Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten
            Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des
            Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung
            des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
          </p>
        </div>
        <h4>3.8 Recht auf Datenübertragbarkeit</h4>
        <p className='c2'>
          Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6 Abs. 1 lit.
          a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß
          Art. 6 Abs. 1 lit. b DSGVO beruht und mithilfe automatisierter
          Verfahren erfolgt, haben Sie das Recht, gemäß Art. 20 DSGVO Ihre
          personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
          strukturierten, gängigen und maschinenlesbaren Format zu erhalten und
          einem anderen Verantwortlichen zu übermitteln oder die Übermittlung an
          einen anderen Verantwortlichen zu verlangen, soweit dies technisch
          machbar ist.
        </p>
        <h4>3.9 Widerspruchsrecht</h4>
        <div>
          <p className='c2'>
            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
            Interessenabwägung nach Art. 6 Abs. 1 lit. f DSGVO stützen, haben
            Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen
            Daten Widerspruch einzulegen; dies gilt auch für ein auf diese
            Bestimmung gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf
            der eine Verarbeitung beruht, entnehmen Sie dieser
            Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre
            betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei
            denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
            oder die Verarbeitung dient der Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
            DSGVO).
          </p>
          <p className='c2'>
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
            widersprechen, werden Ihre personenbezogenen Daten anschließend
            nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach
            Art. 21 Abs. 2 DSGVO).
          </p>
          <p className='c2'>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
            Diensten der Informationsgesellschaft - ungeachtet der Richtlinie
            2002/58/EG - Ihr Widerspruchsrecht mittels automatisierter Verfahren
            auszuüben, bei denen technische Spezifikationen verwendet werden.
          </p>
        </div>
        <h4>
          3.10 Beschwerderecht bei der zuständigen Aufsichtsbehörde gemäß Art.
          77 DSGVO
        </h4>
        <div>
          <p className='c2'>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <p className='c2'>Die für uns zuständige Aufsichtsbehörde ist:</p>
          <p className='c2'>Bayerisches Landesamt für Datenschutzaufsicht</p>
          <p className='c2'>Promenade 18</p>
          <p className='c2'>91522 Ansbach</p>
          <p className='c2'>Postanschrift:</p>
          <p className='c2'>Postfach 1349, 91504 Ansbach</p>
          <p className='c2'>Telefon: 0981/180093-0</p>
          <p className='c2'>E-Mail: poststelle@lda.bayern.de</p>
          <p className='c2'>Internet: https://www.lda.bayern.de</p>
        </div>
        <h3>Gültigkeit und Änderung dieser Datenschutzerklärung</h3>
        <div>
          <p className='c2'>
            Diese Datenschutzerklärung gilt ab dem 25. September 2024. Wir
            behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
            unter Beachtung der geltenden Datenschutzvorschriften zu ändern.
            Dies kann z.B. zur Einhaltung neuer Gesetzesbestimmungen oder zur
            Berücksichtigung der Änderungen unserer Website bzw. neuer
            Dienstleistungen auf unserer Website erforderlich sein. Es gilt die
            zum Zeitpunkt Ihres Besuchs abrufbare Fassung.
          </p>
          <p className='c2'>
            Sollte diese Datenschutzerklärung geändert werden, beabsichtigen
            wir, Änderungen an unserer Datenschutzerklärung auf dieser Seite
            bekannt zu geben, sodass Sie umfassend darüber informiert sind,
            welche personenbezogene Daten wir sammeln, wie wir sie verarbeiten
            und unter welchen Umständen sie weitergegeben werden können.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className='CookieDeklaration c4' id='cookiedeclaration'>
        <h2>CookieDeclaration</h2>
      </div>
    </div>
  );
};
