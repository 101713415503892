import React from 'react';
import { FaPlus } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import { AdminPostContentService } from '../../../service/post';
import { PostChildNavigationSection } from './child';

interface PostNavigationSectionProps {
  id: string;
  content: AdminPostContentService;
}

export const PostNavigationSection = observer(
  ({ id, content }: PostNavigationSectionProps) => {
    const children = React.useMemo(
      () => content.children?.slice() ?? [],
      [content.children]
    );

    return (
      <div id={id} className='navigationsection'>
        <div className='navigationcontent'>
          <div className='leftpart'>
            <h3 className='sectionheader'>{content.id}</h3>
          </div>
          <div className='rightpart'>
            <FaPlus
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={content.addChild}
            />
          </div>
        </div>
        <div className='navigationchildren'>
          {children.map((child) => (
            <PostChildNavigationSection
              id={child.data?.id ?? child.id}
              key={child.data?.id ?? child.id}
              content={child}
              onRemove={content.removeChild}
              onReorder={content.reorder}
            />
          ))}
        </div>
      </div>
    );
  }
);
