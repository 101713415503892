import moment from 'moment';

export type FirebaseBuchung = {
  id: string;
  start: string;
  end: string;
  startsAtFirstHalf?: boolean;
  endsAtSecondHalf?: boolean;
  createdAt: string;
  updatedAt: string;
};

export interface Buchung {
  id: string;
  start: moment.Moment;
  end: moment.Moment;
  startsAtFirstHalf?: boolean;
  endsAtSecondHalf?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const mapFirebaseBuchungToBuchung = (
  firebase: FirebaseBuchung
): Buchung => ({
  id: firebase.id,
  start: moment(firebase.start),
  end: moment(firebase.end),
  startsAtFirstHalf: firebase.startsAtFirstHalf,
  endsAtSecondHalf: firebase.endsAtSecondHalf,
  createdAt: new Date(firebase.createdAt),
  updatedAt: new Date(firebase.updatedAt),
});
