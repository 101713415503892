import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorBoundaryView } from './view';

interface Props {
  children?: ReactNode;
  logger?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!this.props.logger) return;
    this.props.logger(error, errorInfo);
  }

  public render = () =>
    this.state.hasError ? <ErrorBoundaryView /> : this.props.children;
}
