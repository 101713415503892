import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.css';
import { FirebaseImage } from '../image/firebase';
import { storage } from 'src/configuration';

type Props = {
  photoSliderId: string;
  caller: string;
  id: string;
  pictures: string[];
};

export const PhotoSlider = ({ photoSliderId, caller, id, pictures }: Props) => {
  const [activePicture, setActivePicture] = useState<number>(0);
  const [isLoaded, setLoaded] = React.useState(false);

  const handleLoaded = (_: string) => {
    setLoaded(true);
  };

  const changeSpecific = (chosen: number) => {
    if (chosen === activePicture) return;
    setActivePicture(chosen);
    removeClasses();
  };

  const before = () => {
    if (activePicture === 0) {
      setActivePicture(pictures.length - 1);
    } else {
      setActivePicture(activePicture - 1);
    }
    removeClasses();
  };

  const next = () => {
    if (activePicture < pictures.length - 1) {
      setActivePicture(activePicture + 1);
    } else {
      setActivePicture(0);
    }
    removeClasses();
  };

  const removeClasses = () => {
    document
      .getElementById(photoSliderId)
      ?.getElementsByClassName('ellipse active')[0]
      .classList.remove('active');
    document
      .getElementById(photoSliderId)
      ?.getElementsByClassName('photoSliderImg showing')[0]
      .classList.remove('showing');
  };

  return (
    <div className='photoSliderContainer'>
      <div className='photoSlider centerFlex' id={photoSliderId}>
        <div className='photoDisplay'>
          {pictures.map((picture, i) => (
            <FirebaseImage
              key={`${photoSliderId}Picture${i}`}
              img={picture}
              className={`photoSliderImg${
                i === activePicture ? ' showing' : ''
              }`}
              storage={storage}
              storageprev={`${caller}/${id}`}
              onLoaded={handleLoaded}
              loader={
                <Skeleton
                  className={`sliderskeleton${
                    i === activePicture ? ' showing' : ''
                  }`}
                />
              }
            />
          ))}
        </div>
        {isLoaded && pictures.length > 1 && (
          <div className='controlPanel'>
            <div className='sideControl'>
              <div className='sideControlIcon' onClick={before} />
            </div>
            <div className='pictureControl'>
              {pictures.map((_, i) => {
                return (
                  <div
                    className={`ellipse${i === activePicture ? ' active' : ''}`}
                    key={`${photoSliderId}ControlElement${i}`}
                    onClick={() => changeSpecific(i)}
                  />
                );
              })}
            </div>
            <div className='sideControl rightSideControl'>
              <div className='sideControlIcon' onClick={next} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
