export enum AnalysisInfoLevel {
  Success = 'Success',
  Error = 'Error',
}

export class AnalysisInfo {
  constructor(
    public readonly message: string,
    public readonly level: AnalysisInfoLevel
  ) {}

  public static success(message: string): AnalysisInfo {
    return new AnalysisInfo(message, AnalysisInfoLevel.Success);
  }

  public static error(message: string): AnalysisInfo {
    return new AnalysisInfo(message, AnalysisInfoLevel.Error);
  }
}
