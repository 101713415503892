import React from 'react';
import { ContactForm } from '../../Components/ContactForm';

import './index.css';

export const ContactPage = () => {
  return (
    <div className='content'>
      <div className='heroSection'>
        <img className='heroImage' alt='WTD61 Luftaufnahme' src='/home.jpg' />
        <div className='heroSectionBackground' />
        <h1 className='heroSectionHeader'>
          <div>Betreuung.</div>
          <div>Tradition.</div>
          <div>Gemeinschaft.</div>
        </h1>
      </div>
      <div className='contactContent'>
        <div className='section'>
          <h2>Kontakt</h2>
          <div className='contactCombination'>
            <div className='smallIcon phoneIcon' />
            <a href='tel:+4984598113301' className='contactCombination c2'>
              +49 8459 81 13301
            </a>
          </div>
          <div className='contactCombination'>
            <div className='smallIcon emailIcon' />
            <a
              href='mailTo:info@btg-manching.de'
              className='contactCombination c2'
            >
              info@btg-manching.de
            </a>
          </div>
          <div className='contactCombination'>
            <div className='smallIcon mapPinIcon' />
            <a
              href='https://www.google.com/maps/place/Rechliner+Str.+1,+85077+Manching/@48.7028762,11.5136452,14.46z/data=!4m6!3m5!1s0x479e542a9d1059a3:0x9909b8ce90bc281!8m2!3d48.7069654!4d11.5195654!16s%2Fg%2F11sbwrjgsl?entry=ttu'
              className='contactCombination c2'
              target='_blank'
              rel='noreferrer'
            >
              Rechliner Straße 1, 85077 Manching
            </a>
          </div>
        </div>
        <div className='section'>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};
