import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { PreviewFile } from 'src/types/content';
import { EditableContent } from '../../domain/content';
import { AdminOverviewService } from './overview';
import { BookingsNetwork } from '../network/bookings/crud';
import moment from 'moment';
import { AdminBuchung, DefaultBuchung } from '../../domain/booking';

export class AdminBookingContentService {
  private _id: string;
  private _children?: AdminBookingChildContentService[];
  private _removed: string[] = [];

  get id(): string {
    return this._id;
  }

  get children(): AdminBookingChildContentService[] | undefined {
    return this._children;
  }

  get removed(): string[] {
    return this._removed;
  }

  private set children(value: AdminBookingChildContentService[] | undefined) {
    this._children = value ? [...value] : undefined;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
  }

  static fromEditableContent = (
    content: EditableContent<AdminBuchung>
  ): AdminBookingContentService => {
    var service = new AdminBookingContentService(content.id);

    service.children = content.children?.map(
      AdminBookingChildContentService.fromEditableContent
    );

    return service;
  };

  addChild = () => {
    if (!this.children) this.children = [];
    var service = new AdminBookingChildContentService(uuidv4());

    this.children = [...this.children, service];

    AdminOverviewService.setSelected({ data: service, type: 'Bookings' });
  };

  removeChild = (id: string) => {
    if (!this.children) return;
    const removing = this.children.find((child) => child.id === id);
    if (!removing) return;

    this.removed.push(id);
    this.children = this.children.filter((child) => child.id !== removing?.id);

    if (AdminOverviewService.instance.selected?.data.id === id)
      AdminOverviewService.setSelected(undefined);
  };

  save = async () => {
    await Promise.all([
      BookingsNetwork.update(this.children),
      BookingsNetwork.delete(this.removed),
    ]);
  };
}

export class AdminBookingChildContentService {
  private _id: string;
  private _data?: AdminBuchung;
  private _crop?: string | PreviewFile;
  private _parent: string[] = ['Booking'];
  private _isNew: boolean = true;

  static mailRegexp: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static phoneRegexp: RegExp = /^[\+]?[0-9 ]{3,}$/;

  get id(): string {
    return this._id;
  }

  get data(): AdminBuchung | undefined {
    return this._data;
  }

  get parent(): string[] {
    return this._parent;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  private set data(value: AdminBuchung | undefined) {
    this._data = value;
  }

  get crop() {
    return this._crop;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
    this._data = {
      ...DefaultBuchung,
      id: id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  updateStart = (date: Date | null) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      start: moment(date),
      end: moment(date),
    };
  };

  updateStartsAtFirstHalf = (value: boolean) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      startsAtFirstHalf: value,
    };
  };

  updateEnd = (date: Date | null) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      end: moment(date),
    };
  };

  updateEndsAtSecondHalf = (value: boolean) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      endsAtSecondHalf: value,
    };
  };

  updateReservedBy = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      reservedBy: value,
    };
  };

  updateMail = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      mail: value,
    };
  };

  updatePhone = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      phone: value,
    };
  };

  updateType = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      type: value,
    };
  };

  static fromEditableContent = (
    content: EditableContent<AdminBuchung>
  ): AdminBookingChildContentService => {
    var service = new AdminBookingChildContentService(content.id);

    service.data = content.data;
    service._isNew = false;

    return service;
  };

  isUploaded = () => {
    runInAction(() => {
      this._isNew = false;
      if (this._data)
        this._data = {
          ...this._data,
        };
    });
  };
}
