import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import * as Components from 'src/Components';
import * as Services from 'src/Services';
import * as Admin from 'src/administration';
import { storage, store } from './configuration';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const logError = (error: Error, info: ErrorInfo) =>
  Services.AnalysisApi.log(Services.AnalysisEvent.ERROR, {
    description: `error: ${error}; info: ${info}`,
  });

root.render(
  <BrowserRouter>
    <Components.ErrorBoundary logger={logError}>
      <Routes>
        <Route
          path='/admin/*'
          element={<Admin.AdminPage storage={storage} firestore={store} />}
        />
        <Route path='*' element={<App />} />
      </Routes>
    </Components.ErrorBoundary>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
