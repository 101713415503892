import React from 'react';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface SubmitButtonProps {
  label: string | JSX.Element;
  onSubmit: () => Promise<boolean> | boolean;
  disabled?: boolean;
  href?: string;
  className?: string;
}

export const SubmitButton = ({
  onSubmit: obSubmit,
  ...props
}: SubmitButtonProps) => {
  const [success, setSuccess] = React.useState<boolean>();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    if (props.disabled) return;
    setLoading(true);
    var success = await obSubmit();
    setTimeout(() => {
      setLoading(false);
      setSuccess(success);
      setTimeout(() => setSuccess(undefined), 2000);
    }, 250);
  };

  const overlayClassnameExtra = React.useMemo(() => {
    if (!loading && success === undefined) return 'overlay';
    if (loading) return 'overlay loading';
    if (success === true) return 'overlay success';
    if (success === false) return 'overlay error';
  }, [success, loading]);

  return (
    <div className='submitbutton'>
      <Button onClick={handleClick} {...props} />
      <div className={overlayClassnameExtra}>
        {loading && <div className='loader'></div>}
        {success === true && (
          <div className='success'>
            <FontAwesomeIcon icon={faCheck} size='xl' />
          </div>
        )}
        {success === false && (
          <div className='error'>
            <FontAwesomeIcon icon={faXmark} size='xl' />
          </div>
        )}
      </div>
    </div>
  );
};
