import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { stateToHTML } from 'draft-js-export-html';
import parse from 'html-react-parser';

import * as Components from 'src/Components';
import { Merchandise } from '../../domain/merchandise';
import { generateSizeText } from '../helper/extension';

import './components.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

interface MerchDetailProps {
  merchandise: Merchandise;
  loading: boolean;
}

export const MerchDetail = ({ merchandise, loading }: MerchDetailProps) => {
  const requestAvailable = React.useRef(false).current;

  const contact = () => {
    var supportEmail = 'info@btg-manching.de';
    var formattedBody = `Guten Tag, \n\ngerne möchte ich den folgenden Artikel bei Ihnen bestellen. \n\n    Mitgliedsnummer: \n    Artikel: ${merchandise.name} \n    Anzahl: \n    Lieferadresse: \n\nMit freundlichen Grüßen,\n\n`;
    window.location.href = `mailto:${supportEmail}?subject=Bestellung ${encodeURIComponent(
      merchandise.name
    )}&body=${encodeURIComponent(formattedBody)}`;

    return true;
  };

  return (
    <div className='withinfo'>
      <Components.InfoBar
        type='info'
        message='Derzeit ist unser Merchandise nur über unser Vereinsheim beziehbar!'
      />
      {loading ? (
        <Skeleton className='merchdetailsskeleton' />
      ) : (
        <div className='merchdetail'>
          <div className='merchdetailimages'>
            <Components.AlternativePhotoSlider
              id={merchandise.id}
              images={merchandise.pictures.map((pic) => pic as string)}
            />
          </div>
          <div className='merchdetailinfocontainer'>
            <div className='merchdetailinfo'>
              <h2 className='merchdetailheader'>{merchandise.name}</h2>
              <div className='merchdetailpricing'>
                <p className='merchcosts c1'>{merchandise.costs} €*</p>
                <p className='merchcostextra c1'>inkl. MwSt.</p>
              </div>
              {merchandise.size && (
                <p className='merchsize c2'>{generateSizeText(merchandise)}</p>
              )}
              <p className='alignment c1'>
                {parse(
                  stateToHTML(merchandise.description.getCurrentContent())
                )}
              </p>
              <div className='merchdetailavailability'>
                <div
                  className={
                    merchandise.available
                      ? 'merchstatus available'
                      : 'merchstatus'
                  }
                />
                <p className='c2'>
                  {merchandise.available ? 'Verfügbar' : 'Ausverkauft'}
                </p>
              </div>
            </div>
            <div className='merchdetailbutton'>
              <Components.SubmitButton
                label='Anfragen'
                onSubmit={contact}
                disabled={!requestAvailable || !merchandise.available}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
