import axios from 'axios';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  FirebasePersonType,
  PersonType,
  mapFirebasePTToPT,
} from 'src/types/person';

export const getTeam = async (): Promise<PersonType[]> => {
  try {
    const response = await axios.get<FirebasePersonType[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/team'
    );

    const data = response.data
      .filter((data) => data.id !== 'Ansprechpartner')
      .map(mapFirebasePTToPT);

    return data.sort((left, right) =>
      left.position > right.position ? -1 : 1
    );
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, { error: `getTeam: ${error}` });
    return [];
  }
};
