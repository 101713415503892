import React from 'react';

import './index.css';

interface InfoBarProps {
  message: string;
  type: 'info' | 'error';
}

export const InfoBar = ({ message, type }: InfoBarProps) => {
  return <p className={`infobar ${type} cc2`}>{message}</p>;
};
