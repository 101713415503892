import React, { useEffect, useRef, useState } from 'react';
import { DefaultPersonType, PersonType } from '../../types/person';
import { TeamMember } from '../Member';
import { useTeamRequest } from '../../Network/fetchAPIs';
import Skeleton from 'react-loading-skeleton';
import './index.css';
import { getTeam } from 'src/Pages/Team/application';

type Props = { caller: string };

export const MemberContainer = ({ caller }: Props) => {
  const init = useRef(false);
  const [content, setContent] = useState<PersonType[]>([
    DefaultPersonType,
    DefaultPersonType,
    DefaultPersonType,
    DefaultPersonType,
    DefaultPersonType,
  ]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (init.current) return;

    getTeam().then((response) => {
      setContent(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    init.current = true;
  }, []);

  return (
    <section className='section'>
      <h2>{caller}</h2>
      <div className='memberGrid'>
        {content.map((person, iterator) =>
          loading ? (
            <Skeleton className='memberskeleton' key={caller + iterator} />
          ) : (
            <TeamMember person={person} key={caller + iterator} />
          )
        )}
      </div>
    </section>
  );
};
