import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

export type FirebasePreviewContent = {
  id: string;
  header: string;
  text: string;
  position: number;
  author?: string;
  date?: string;
  thumbnailPicture?: string | PreviewFile;
  pictures?: (string | PreviewFile)[];
  createdAt: string;
  updatedAt: string;
};

export type PreviewContent = {
  id: string;
  header: string;
  text: EditorState;
  position: number;
  author?: string;
  date?: string;
  thumbnailPicture?: string | PreviewFile;
  pictures?: (string | PreviewFile)[];
  createdAt: Date;
  updatedAt: Date;
};

export const DefaultPreviewContent: PreviewContent = {
  id: '',
  header: '',
  text: EditorState.createEmpty(),
  position: 0,
  author: '',
  thumbnailPicture: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export class PreviewFile {
  constructor(public file: File, public url: string) {}
}

export const mapFirebasePCToPC = (
  data: FirebasePreviewContent
): PreviewContent => ({
  header: data.header,
  id: data.id,
  position: data.position,
  text: EditorState.createWithContent(convertFromRaw(JSON.parse(data.text))),
  author: data.author,
  date: data.date,
  thumbnailPicture: data.thumbnailPicture,
  pictures: data.pictures,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
});

export const mapPCToFirebasePC = (
  data: PreviewContent
): FirebasePreviewContent => ({
  header: data.header,
  id: data.id,
  position: data.position,
  text: JSON.stringify(convertToRaw(data.text.getCurrentContent())),
  author: data.author,
  date: data.date,
  thumbnailPicture: data.thumbnailPicture
    ? mapStringOrPreviewFilePictureToStringPicture(data.thumbnailPicture)
    : undefined,
  pictures: data.pictures?.map(mapStringOrPreviewFilePictureToStringPicture),
  createdAt: data.createdAt.toISOString(),
  updatedAt: data.updatedAt.toISOString(),
});

export const mapStringOrPreviewFilePictureToStringPicture = (
  img: string | PreviewFile
) => {
  if (typeof img === 'string') return img;

  return img.file.name;
};
