import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import Compressor from 'compressorjs';
import { PreviewFile } from 'src/types/content';
import { EditableContent } from '../../domain/content';
import { AdminOverviewService } from './overview';
import {
  DefaultMerchandise,
  Merchandise,
  Size,
} from 'src/Pages/merchandise/domain/merchandise';
import { MerchandiseNetwork } from '../network/merchandise/crud';
import { EditorState } from 'draft-js';

export class AdminMerchandiseContentService {
  private _id: string;
  private _children?: AdminMerchandiseChildContentService[];
  private _removed: string[] = [];

  get id(): string {
    return this._id;
  }

  get children(): AdminMerchandiseChildContentService[] | undefined {
    return this._children;
  }

  get removed(): string[] {
    return this._removed;
  }

  private set children(
    value: AdminMerchandiseChildContentService[] | undefined
  ) {
    this._children = value ? [...value] : undefined;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
  }

  static fromEditableContent = (
    content: EditableContent<Merchandise>
  ): AdminMerchandiseContentService => {
    var service = new AdminMerchandiseContentService(content.id);

    service.children = content.children?.map(
      AdminMerchandiseChildContentService.fromEditableContent
    );

    return service;
  };

  addChild = () => {
    if (!this.children) this.children = [];
    var service = new AdminMerchandiseChildContentService(uuidv4());
    service.updatePosition(this.children.length);

    this.children = [...this.children, service];

    AdminOverviewService.setSelected({ data: service, type: 'Merchandise' });
  };

  reorder = (content: AdminMerchandiseChildContentService, to: number) => {
    if (!this.children) return;
    const direction = content.position < to ? 'UP' : 'DOWN';
    this.children = [
      ...this.children.map((child) => {
        if (child.id === content.id) child.updatePosition(to);
        else if (direction === 'UP' && child.position <= to)
          child.updatePosition(child.position - 1);
        else if (direction === 'DOWN' && child.position >= to)
          child.updatePosition(child.position + 1);
        return child;
      }),
    ].sort((left, right) => (left.position > right.position ? -1 : 1));
  };

  removeChild = (id: string) => {
    if (!this.children) return;
    const removing = this.children.find((child) => child.id === id);
    if (!removing) return;

    this.removed.push(id);
    this.children = this.children.filter((child) => child.id !== removing?.id);

    if (AdminOverviewService.instance.selected?.data.id === id)
      AdminOverviewService.setSelected(undefined);
  };

  save = async () => {
    await Promise.all([
      MerchandiseNetwork.update(this.children),
      MerchandiseNetwork.delete(this.removed),
    ]);
  };
}

export class AdminMerchandiseChildContentService {
  private _id: string;
  private _data?: Merchandise;
  private _crop?: string | PreviewFile;
  private _parent: string[] = ['Merchandise'];
  private _isNew: boolean = true;

  static floatRegexp: RegExp = /^[+-]?\d+(\.\d+)?$/;

  get id(): string {
    return this._id;
  }

  get data(): Merchandise | undefined {
    return this._data;
  }

  get position(): number {
    return this.data?.position ?? 0;
  }

  get parent(): string[] {
    return this._parent;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  private set data(value: Merchandise | undefined) {
    this._data = value;
  }

  get crop() {
    return this._crop;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
    this._data = {
      ...DefaultMerchandise,
      id: id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  updateName = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      name: value,
    };
  };

  updatePosition = (value: number) => {
    if (!this.data) return;
    this.data = { ...this.data, position: value, updatedAt: new Date() };
  };

  updateCosts = (value: string) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      costs: value,
    };
  };

  updateDescription = (value: EditorState) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      description: value,
    };
  };

  updateWidth = (value: string) => {
    if (!this.data) return;
    var size: Size = {
      height: '0',
      length: '0',
      width: '0',
    };
    if (this.data?.size) size = { ...this.data?.size };
    this.data = {
      ...this.data,
      size: {
        ...size,
        width: value,
      },
    };
  };

  updateHeight = (value: string) => {
    if (!this.data) return;
    var size: Size = {
      height: '0',
      length: '0',
      width: '0',
    };
    if (this.data?.size) size = { ...this.data?.size };
    this.data = {
      ...this.data,
      size: {
        ...size,
        height: value,
      },
    };
  };

  updateLength = (value: string) => {
    if (!this.data) return;
    var size: Size = {
      height: '0',
      length: '0',
      width: '0',
    };
    if (this.data?.size) size = { ...this.data?.size };
    this.data = {
      ...this.data,
      size: {
        ...size,
        length: value,
      },
    };
  };

  updateAvailable = (value: boolean) => {
    if (!this.data) return;
    this.data = {
      ...this.data,
      available: value,
    };
  };

  addImg = (img: File) => {
    new Compressor(img, {
      quality: 0.8,
      maxWidth: 1024,
      success: (compressed) => {
        var file: File;
        if (compressed instanceof File) {
          file = compressed;
        } else {
          file = new File([compressed], img.name);
        }

        if (!this.data) return;

        const urlCreator = window.URL || window.webkitURL;
        const preview: PreviewFile = {
          file: file,
          url: urlCreator.createObjectURL(file),
        };

        if (!this.data.pictures) this.data.pictures = [];

        this.data = {
          ...this.data,
          pictures: [...this.data.pictures, preview],
          updatedAt: new Date(),
        };
      },
    });
  };

  updatePictureFromCrop = (img: File, previous: string | PreviewFile) => {
    if (!this.data || !this.data.pictures) return;
    const previousIndex = this.data.pictures.findIndex(
      (image) => image === previous
    );
    if (previousIndex === -1) return;

    const urlCreator = window.URL || window.webkitURL;
    const preview: PreviewFile = {
      file: img,
      url: urlCreator.createObjectURL(img),
    };

    this.data = {
      ...this.data,
      pictures: [
        ...this.data.pictures.filter((_, index) => index < previousIndex),
        preview,
        ...this.data.pictures.filter((_, index) => index > previousIndex),
      ],
      updatedAt: new Date(),
    };
  };

  removeImg = (img: string | PreviewFile) => {
    if (!this.data || !this.data.pictures) return;

    this.data = {
      ...this.data,
      pictures: [...this.data.pictures.filter((image) => image !== img)],
      updatedAt: new Date(),
    };
  };

  setCrop(img?: string | PreviewFile) {
    this._crop = img;
  }

  static fromEditableContent = (
    content: EditableContent<Merchandise>
  ): AdminMerchandiseChildContentService => {
    var service = new AdminMerchandiseChildContentService(content.id);

    service.data = content.data;
    service._isNew = false;

    return service;
  };

  isUploaded = () => {
    runInAction(() => {
      this._isNew = false;
      if (this._data)
        this._data = {
          ...this._data,
        };
    });
  };
}
