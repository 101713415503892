import axios from 'axios';
import { AdminOverviewService } from '../../service/overview';
import { EditableContent } from '../../../domain/content';
import { AdminBookingChildContentService } from '../../service/booking';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  FirebaseAdminBuchung,
  AdminBuchung,
  mapFirebaseAdminBuchungToAdminBuchung,
  mapAdminBuchungToFirebaseAdminBuchung,
} from '../../../domain/booking';
import { AdminAgent } from 'src/administration/contracts/agent';

export class BookingsNetwork {
  static list = async (): Promise<EditableContent<AdminBuchung>> => {
    const content = await axios.get<FirebaseAdminBuchung[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/bookings/detailed'
    );

    if (content.status !== 200) {
      AnalysisApi.log(AnalysisEvent.ERROR, 'Error while getting bookings');
      throw new Error('Something went wrong on the server');
    }

    return {
      id: 'Buchungen',
      isMovable: false,
      isRemovable: false,
      children: content.data.map((element) => ({
        id: element.id,
        data: mapFirebaseAdminBuchungToAdminBuchung(element),
        isMovable: false,
        isRemovable: false,
        parent: ['Buchungen'],
      })),
    };
  };

  static update = async (content?: AdminBookingChildContentService[]) => {
    if (!content) return;
    if (!AdminOverviewService.instance.storage) return;

    for (var child of content) {
      if (!child.data) continue;

      const data = mapAdminBuchungToFirebaseAdminBuchung(child.data);

      try {
        if (child.isNew)
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/bookings`,
            data
          );
        else
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/bookings/${child.id}`,
            data
          );

        child.isUploaded();
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while updating booking ${child}, ${err}`
        );
      }
    }
  };

  static delete = async (content: string[]) => {
    if (content.length === 0) return;

    for (var child of content) {
      try {
        await AdminAgent.delete(
          `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/bookings/${child}/delete`
        );
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while deleting booking ${child}, ${err}`
        );
      }
    }
  };
}
