import axios from 'axios';
import * as Storage from 'firebase/storage';
import {
  FirebasePreviewContent,
  mapFirebasePCToPC,
  mapPCToFirebasePC,
  PreviewContent,
} from 'src/types/content';
import { uploadPictures } from '../common/crud';
import { AdminOverviewService } from '../../service/overview';
import { AdminHappeningsChildContentService } from '../../service/happenings';
import { EditableContent } from '../../../domain/content';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import { AdminAgent } from 'src/administration/contracts/agent';

export class HappeningsNetwork {
  static list = async (): Promise<EditableContent<PreviewContent>> => {
    const content = await axios.get<FirebasePreviewContent[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening'
    );

    if (content.status !== 200) {
      throw new Error('Something went wrong on the server');
    }

    return {
      id: 'Veranstaltung',
      isMovable: false,
      isRemovable: false,
      children: content.data
        .sort((left, right) => (left.position > right.position ? -1 : 1))
        .map((element) => ({
          id: element.id,
          data: mapFirebasePCToPC(element),
          isMovable: true,
          isRemovable: true,
          parent: ['Veranstaltung'],
        })),
    };
  };

  static update = async (content?: AdminHappeningsChildContentService[]) => {
    if (!content) return;
    if (!AdminOverviewService.instance.storage) return;

    for (var child of content) {
      if (!child.data) continue;

      const data = mapPCToFirebasePC(child.data);

      try {
        var ref = Storage.ref(
          AdminOverviewService.instance.storage,
          `Veranstaltung/${child.id}`
        );

        await uploadPictures(
          ref,
          child.data.pictures,
          child.data.thumbnailPicture
        );

        if (child.isNew)
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening`,
            data
          );
        else
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening/${child.id}`,
            data
          );

        child.isUploaded();
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while updating booking ${child}, ${err}`
        );
      }
    }
  };

  static delete = async (content: string[]) => {
    if (content.length === 0) return;

    for (var child of content) {
      try {
        await AdminAgent.delete(
          `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening/${child}/delete`
        );
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while deleting booking ${child}, ${err}`
        );
      }
    }
  };
}
