import React from 'react';
import './index.css'

import { ButtonType } from '../../types/buttons';

export const Button = ({ primary, action, text }: ButtonType) => {
  return (
    <button
      className={`button ${primary ? 'primary' : 'secondary'} `}
      onClick={action}
    >
      {text}
    </button>
  );
};
