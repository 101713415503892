import * as Firestore from 'firebase/firestore';
import { store } from 'src/configuration';
import { FirebasePreviewContent } from 'src/types/content';
import { AdminOverview } from '../../domain/overview';
import { PostsNetwork } from './posts/crud';
import { HappeningsNetwork } from './happenings/crud';
import { TeamNetwork } from './team/crud';
import { BookingsNetwork } from './bookings/crud';
import { MerchandiseNetwork } from './merchandise/crud';

export const getOverview = async (): Promise<AdminOverview> => {
  const posts = await PostsNetwork.list();
  const happenings = await HappeningsNetwork.list();
  const team = await TeamNetwork.list();
  const bookings = await BookingsNetwork.list();
  const merchandise = await MerchandiseNetwork.list();

  return {
    posts,
    happenings,
    team,
    bookings,
    merchandise,
  };
};

export const getCollection = async (
  collection: string
): Promise<FirebasePreviewContent[]> => {
  var coll = Firestore.collection(store, collection);
  const snapshot = await Firestore.getDocs(coll);
  const content = snapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as FirebasePreviewContent[];

  return content;
};
