import axios from 'axios';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  FirebasePreviewContent,
  mapFirebasePCToPC,
  PreviewContent,
} from 'src/types/content';

export const getHappenings = async (): Promise<PreviewContent[]> => {
  try {
    const response = await axios.get<FirebasePreviewContent[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening'
    );

    const data = response.data.map(mapFirebasePCToPC);

    return data;
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, { error: `getHappenings: ${error}` });
    return [];
  }
};
