import { makeAutoObservable, runInAction, when } from 'mobx';
import axios from 'axios';
import * as Services from 'src/Services/analysis/api';
import { Content, ContentWithHtml } from '../../domain/content';

export class ContactService {
  private static innerinstance: ContactService = new ContactService();

  static get instance() {
    return this.innerinstance;
  }

  private started: boolean = false;

  name: Content = { text: '', valid: false };
  mail: Content = { text: '', valid: false };
  subject: Content = { text: '', valid: false };
  content: ContentWithHtml = { text: '', html: '', valid: false };
  recaptcha: boolean = false;

  nameRegexp: RegExp = /^[a-zA-Z äÄöÖüÜß]+$/;
  mailRegexp: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  subjectRegexp: RegExp =
    /^[a-zA-Z0-9 äÄöÖüÜß_.,\"\'\´\`?!+\-*/&$€@ß()[\]{}]+$/;
  contentRegexp: RegExp =
    /^[a-zA-Z0-9 \n\r\näÄöÖüÜß_.,\"\'\´\`?!+\-*/&$€@ß()[\]{}]+$/;

  responseError: string = '';

  constructor() {
    makeAutoObservable(this);
    when(
      () => this.started,
      () =>
        Services.AnalysisApi.log(Services.AnalysisEvent.FORM_START, {
          form_id: 'contact',
          form_name: 'Kontaktformular',
          form_destination: 'mail',
        })
    );
  }

  private handleNameChange(value: string, valid: boolean) {
    this.name = { text: value, valid };
  }

  private handleMailChange(value: string, valid: boolean) {
    this.mail = { text: value, valid };
  }

  private handleSubjectChange(value: string, valid: boolean) {
    this.subject = { text: value, valid };
  }

  private handleContentChange(value: string, html: string, valid: boolean) {
    this.content = { text: value, html, valid };
  }

  static handleNameChange = (value: string, valid: boolean) => {
    ContactService.instance.handleNameChange(value, valid);
  };

  static handleMailChange = (value: string, valid: boolean) => {
    ContactService.instance.handleMailChange(value, valid);
  };

  static handleSubjectChange = (value: string, valid: boolean) => {
    ContactService.instance.handleSubjectChange(value, valid);
  };

  static handleContentChange = (
    value: string,
    html: string,
    valid: boolean
  ) => {
    ContactService.instance.handleContentChange(value, html, valid);
  };

  static handleRecaptchaSuccess(value: any) {
    if (typeof value === 'boolean' && value === false) return;
    if (value === 'false') return;
    if (value instanceof Error) return;
    ContactService.instance.recaptcha = true;
  }

  static handleRecaptchaInvalid() {
    ContactService.instance.recaptcha = false;
  }

  get valid() {
    return (
      this.name.valid &&
      this.mail.valid &&
      this.subject.valid &&
      this.content.valid
    );
  }

  static verifyRecaptcha = async (token: string) => {
    try {
      const response = await axios.post<{
        success: boolean;
        score: number;
      }>(
        'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/security/verify',
        {
          token: token,
        }
      );

      return response.data.success;
    } catch (error) {
      return false;
    }
  };

  static submit = async () => {
    if (ContactService.instance.valid) {
      try {
        const { name, mail, subject, content } = ContactService.instance;

        const response = await axios.post<string>(
          'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/mail',
          {
            to: mail.text,
            subject: subject.text,
            text: content.text,
            name: name.text,
          }
        );
        await Services.AnalysisApi.log(Services.AnalysisEvent.FORM_SUBMIT, {
          form_id: 'contact',
          form_name: 'Kontaktformular',
          form_destination: 'mail',
          form_submit_text: 'Senden',
        });
        if (response.status !== 200) {
          this.instance.responseError = response.data;
          Services.AnalysisApi.log(
            Services.AnalysisEvent.ERROR,
            {
              description: `${this.instance.responseError}`,
            },
            this.instance.responseError
          );
          return false;
        }
        this.reset();
        return true;
      } catch (error: any) {
        this.instance.responseError = error.message;
        Services.AnalysisApi.log(
          Services.AnalysisEvent.ERROR,
          {
            description: `${this.instance.responseError}`,
          },
          this.instance.responseError
        );
        return false;
      }
    }
    return false;
  };

  static reset() {
    runInAction(() => {
      this.instance.name = { text: '', valid: false };
      this.instance.mail = { text: '', valid: false };
      this.instance.subject = { text: '', valid: false };
      this.instance.content = { text: '', html: '', valid: false };
      this.instance.responseError = '';
    });
  }
}
