import React, { useEffect } from 'react';
import './index.css';
import { observer } from 'mobx-react-lite';
import { PersonType } from '../../types/person';
import { PersonInformation } from '../PersonInformation';
import { MemberApi } from '../../Services/Member/MemberApi';
import { FirebaseImage } from '../image/firebase';
import { storage } from 'src/configuration';
import Skeleton from 'react-loading-skeleton';

type Props = {
  person: PersonType;
};

export const TeamMember = observer(({ person }: Props) => {
  const isSpaceRightAvailable = () => {
    let elementInformation = document
      .getElementById(person.id)
      ?.getBoundingClientRect();
    let gapHelper = parseInt(
      getComputedStyle(document.body).getPropertyValue('--usualGap')
    );
    if (
      window.screen.width >
      elementInformation!.left + elementInformation!.width * 2 + gapHelper
    ) {
      if (
        elementInformation!.left -
          (elementInformation!.width / 2 + gapHelper) >=
        0
      )
        return 'Middle';
      return 'Right';
    }
    return 'Left';
  };

  const handleSelect = () => {
    isSpaceRightAvailable();
    MemberApi.setMember(person.name);
  };

  useEffect(() => {
    if (MemberApi.getMember() === person.name) {
      document.getElementById(`${person.id}sizing`)!.style.height = `${
        document.getElementById(`${person.id}-Info`)?.getBoundingClientRect()
          .height
      }px`;
    }
  }, [MemberApi.getMember(), person.name]);

  return (
    <div
      className={`member${
        MemberApi.getMember() === person.name ? ' open' : ''
      }`}
      id={person.id}
    >
      <FirebaseImage
        img={person.picture as string}
        className='teamMemberPicture'
        onClick={handleSelect}
        storage={storage}
        storageprev={`Team/${person.id}`}
        loader={<Skeleton className='teamMemberPicture' />}
      />
      {person.name === MemberApi.getMember() && (
        <div className='sizingHelper' id={person.id + 'sizing'}>
          <PersonInformation
            id={`${person.id}-Info`}
            person={person}
            orientation={isSpaceRightAvailable()}
          />
        </div>
      )}
    </div>
  );
});
