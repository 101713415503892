import React from 'react';

import * as Components from 'src/administration/components';
import { AuthorizationState } from 'src/administration/state/authorization';

import './ui.css';

export const LoginUI = () => (
  <div className='logincontainer'>
    <div className='logincard'>
      <h2>Login</h2>
      <Components.SubmitButton
        label='Sign In With Google'
        onSubmit={AuthorizationState.logIn}
        className='login-with-google-btn'
        loadingClassName='google-loader'
      />
    </div>
  </div>
);
