import React from 'react';
import { Article } from '../../Components/Article';
import { HomeContent } from './application/constants';
import { Upcoming } from './application/upcoming';

import './index.css';

export const Home = () => {
  const observer = React.useRef<ResizeObserver>();
  const [maxMainContentHeight, setMaxMainContentHeight] = React.useState(0);

  const observeWith = (entries: ResizeObserverEntry[], _: ResizeObserver) => {
    var first = entries[0];
    setMaxMainContentHeight(first.contentRect.height);
  };

  React.useEffect(() => {
    const homecontent = document.getElementById('homecontent');
    if (!homecontent) return;

    observer.current = new ResizeObserver(observeWith);
    observer.current.observe(homecontent);

    return () => {
      if (observer.current) observer.current?.unobserve(homecontent);
    };
  }, []);

  return (
    <div className='contentcontainer'>
      <div className='heroSection'>
        <img className='heroImage' alt='WTD61 Luftaufnahme' src='/home.jpg' />
        <div className='heroSectionBackground' />
        <h1 className='heroSectionHeader'>
          <div>Betreuung.</div>
          <div>Tradition.</div>
          <div>Gemeinschaft.</div>
        </h1>
      </div>
      <div className='maincontent'>
        <div id='homecontent' className='homecontent'>
          {HomeContent.map((cont, i) => (
            <Article
              caller={'Home'}
              content={cont}
              numerator={i}
              key={cont.header + i}
            />
          ))}
        </div>
        <Upcoming maxHeight={maxMainContentHeight} />
      </div>
    </div>
  );
};
