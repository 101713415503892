import React from 'react';
import { ButtonType } from '../../types/buttons';
import { Button } from '../Button';

type Props = { information: string; buttons: ButtonType[] };

export const ButtonContainer = ({ information, buttons }: Props) => {
  return (
    <div className='actionSection section sectionButton'>
      <h3>{information}</h3>
      <div className='centerFlex buttonContainer'>
        {buttons.map((button, index) => (
          <Button
            text={button.text}
            action={button.action}
            primary={button.primary}
            key={`button-${index}`}
          />
        ))}
      </div>
    </div>
  );
};
