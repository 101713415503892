import { MemberService } from './MemberService';

export class MemberApi {
  static readonly setMember = (member: string) => {
    MemberService.instance.setMember(member);
  };

  static readonly getMember = () => MemberService.instance.getMember();

  static readonly reset = () => MemberService.reset();
}
