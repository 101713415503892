import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { EditorState } from 'draft-js';
import Compressor from 'compressorjs';

import {
  DefaultPreviewContent,
  mapStringOrPreviewFilePictureToStringPicture,
  PreviewContent,
  PreviewFile,
} from 'src/types/content';
import { EditableContent } from '../../domain/content';
import { AdminOverviewService } from './overview';
import { PostsNetwork } from '../network';

export class AdminPostContentService {
  private _id: string;
  private _children?: AdminPostChildContentService[];
  private _removed: string[] = [];

  get id(): string {
    return this._id;
  }

  get children(): AdminPostChildContentService[] | undefined {
    return this._children;
  }

  get removed(): string[] {
    return this._removed;
  }

  private set children(value: AdminPostChildContentService[] | undefined) {
    this._children = value ? [...value] : undefined;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
  }

  static fromEditableContent = (
    content: EditableContent<PreviewContent>
  ): AdminPostContentService => {
    var service = new AdminPostContentService(content.id);

    service.children = content.children?.map(
      AdminPostChildContentService.fromEditableContent
    );

    return service;
  };

  addChild = () => {
    if (!this.children) this.children = [];
    var service = new AdminPostChildContentService(uuidv4());
    service.updatePosition(this.children.length);

    this.children = [...this.children, service];

    AdminOverviewService.setSelected({ data: service, type: 'Post' });
  };

  reorder = (content: AdminPostChildContentService, to: number) => {
    if (!this.children) return;
    const direction = content.position < to ? 'UP' : 'DOWN';
    this.children = [
      ...this.children.map((child) => {
        if (child.id === content.id) child.updatePosition(to);
        else if (direction === 'UP' && child.position <= to)
          child.updatePosition(child.position - 1);
        else if (direction === 'DOWN' && child.position >= to)
          child.updatePosition(child.position + 1);
        return child;
      }),
    ].sort((left, right) => (left.position > right.position ? -1 : 1));
  };

  removeChild = (id: string) => {
    if (!this.children) return;
    const removing = this.children.find((child) => child.id === id);
    if (!removing) return;

    this.removed.push(id);
    this.children = this.children.filter((child) => child.id !== removing?.id);
  };

  save = async () => {
    await Promise.all([
      PostsNetwork.update(this.children),
      PostsNetwork.delete(this.removed),
    ]);
  };
}

export class AdminPostChildContentService {
  private _id: string;
  private _data?: PreviewContent;
  private _crop?: string | PreviewFile;
  private _parent: string[] = ['Beiträge'];
  private _isNew: boolean = true;

  get id(): string {
    return this._id;
  }

  get position(): number {
    return this.data?.position ?? 0;
  }

  get data(): PreviewContent | undefined {
    return this._data;
  }

  get parent(): string[] {
    return this._parent;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  private set data(value: PreviewContent | undefined) {
    this._data = value;
  }

  get crop() {
    return this._crop;
  }

  constructor(id: string) {
    makeAutoObservable(this);

    this._id = id;
    this._data = {
      ...DefaultPreviewContent,
      id: id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  static fromEditableContent = (
    content: EditableContent<PreviewContent>
  ): AdminPostChildContentService => {
    var service = new AdminPostChildContentService(content.id);

    service.data = content.data;
    service._isNew = false;

    return service;
  };

  updateTitle = (value: string, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, header: value, updatedAt: new Date() };
  };

  updateText = (value: EditorState, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, text: value, updatedAt: new Date() };
  };

  updateAuthor = (value: string, valid: boolean) => {
    if (!this.data) return;
    this.data = { ...this.data, author: value };
  };

  updatePosition = (value: number) => {
    if (!this.data) return;
    this.data = { ...this.data, position: value, updatedAt: new Date() };
  };

  setThumbnail = (img: string | PreviewFile) => {
    if (!this.data || !this.data.pictures) return;

    const pictures = [
      ...this.data.pictures.filter((pic) => pic.toString() !== img.toString()),
    ];

    if (this.data.thumbnailPicture) pictures.push(this.data.thumbnailPicture);

    this.data = {
      ...this.data,
      pictures: pictures,
      thumbnailPicture: img,
      updatedAt: new Date(),
    };
  };

  addImg = (img: File) => {
    new Compressor(img, {
      quality: 0.8,
      maxWidth: 1024,
      success: (compressed) => {
        var file: File;
        if (compressed instanceof File) {
          file = compressed;
        } else {
          file = new File([compressed], img.name);
        }

        if (!this.data) return;

        const urlCreator = window.URL || window.webkitURL;
        const preview: PreviewFile = {
          file: file,
          url: urlCreator.createObjectURL(file),
        };

        if (!this.data.pictures) this.data.pictures = [];

        this.data = {
          ...this.data,
          pictures: [...this.data.pictures, preview],
          updatedAt: new Date(),
        };
      },
    });
  };

  updatePictureFromCrop = (img: File, previous: string | PreviewFile) => {
    if (!this.data || !this.data.pictures) return;
    const previousIndex = this.data.pictures.findIndex(
      (image) => image === previous
    );
    if (previousIndex === -1) return;

    const urlCreator = window.URL || window.webkitURL;
    const preview: PreviewFile = {
      file: img,
      url: urlCreator.createObjectURL(img),
    };

    this.data = {
      ...this.data,
      pictures: [
        ...this.data.pictures.filter((_, index) => index < previousIndex),
        preview,
        ...this.data.pictures.filter((_, index) => index > previousIndex),
      ],
      updatedAt: new Date(),
    };
  };

  removeImg = (img: string | PreviewFile) => {
    if (!this.data || !this.data.pictures) return;

    this.data = {
      ...this.data,
      pictures: [...this.data.pictures.filter((image) => image !== img)],
      updatedAt: new Date(),
    };

    if (this.data.thumbnailPicture === img)
      this.data = { ...this.data, thumbnailPicture: this.data.pictures![0] };
  };

  setCrop(img?: string | PreviewFile) {
    this._crop = img;
  }

  isUploaded = () => {
    runInAction(() => {
      this._isNew = false;
      if (this._data)
        this._data = {
          ...this._data,
          pictures: this._data.pictures
            ? [
                ...this._data.pictures.map(
                  mapStringOrPreviewFilePictureToStringPicture
                ),
              ]
            : undefined,
          thumbnailPicture: this._data.thumbnailPicture
            ? mapStringOrPreviewFilePictureToStringPicture(
                this._data.thumbnailPicture
              )
            : undefined,
        };
    });
  };
}
