import React from 'react';

import { Calendar } from './application';

import './ui.css';

export const VereinsraumRequest = () => {
  return (
    <div className='vereinsraum'>
      <h3>Vereinsraum</h3>
      <div className='alignment c1'>
        <p>
          Der Vereinsraum kann über{' '}
          <a
            href={'mailto:info@btg-manching.de?subject=Vereinsraum'}
            className='hrefitalic'
          >
            info@btg-manching.de
          </a>{' '}
          {/* oder über das{' '}
          <a href={'/Kontakt'} className='hrefitalic'>
            Kontaktformular
          </a>{' '} */}
          angefragt werden. Zeitlich wird zwischen Vormittag und Nachmittag
          getrennt. Ist der Raum Vormittags gebucht, muss dieser bis 12:00 Uhr
          Mittags gereinigt übergeben werden.
        </p>
      </div>
      <Calendar />
    </div>
  );
};
