import React from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Switch from 'react-switch';

import * as Components from 'src/administration/components';
import { AdminBookingChildContentService } from '../../../service/booking';

import './editable.css';
import '../editable.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';

interface BookingsEditableProps {
  content: AdminBookingChildContentService;
}

export const BookingsEditable = observer(
  ({ content }: BookingsEditableProps) => {
    const placeholder = React.useMemo(() => {
      if (content !== undefined) return 'Aktualisiere deinen Inhalt...';
      return 'Wählen Sie links den Inhalt, den Sie aktualisieren möchten...';
    }, [content]);

    const updateStart = (date: Date | null) => {
      if (!content) return;
      content.updateStart(date);
    };

    const updateStartsAtFirstHalf = (value: boolean) => {
      if (!content) return;
      content.updateStartsAtFirstHalf(value);
    };

    const updateEnd = (date: Date | null) => {
      if (!content) return;
      content.updateEnd(date);
    };

    const updateEndsAtSecondHalf = (value: boolean) => {
      if (!content) return;
      content.updateEndsAtSecondHalf(value);
    };

    const updateReservedBy = (value: string, _: boolean) => {
      if (!content) return;
      content.updateReservedBy(value);
    };

    const updateMail = (value: string, _: boolean) => {
      if (!content) return;
      content.updateMail(value);
    };

    const updatePhone = (value: string, _: boolean) => {
      if (!content) return;
      content.updatePhone(value);
    };

    const updateType = (value: string, _: boolean) => {
      if (!content) return;
      content.updateType(value);
    };

    return (
      <main className='admineditablecontainer'>
        <div className='admineditable'>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Von</h3>
            <Components.DateInput
              id={`${content?.id}-start`}
              value={content?.data?.start.format('DD.MM.YYYY') ?? ''}
              placeholder={placeholder}
              onChange={updateStart}
              selected={content.data?.start.toDate()}
              minDate={moment().toDate()}
              className='editableinput'
            />
            <div className='editabletoggle'>
              <label>Reservierung ab Vormittags: </label>
              <Switch
                checked={content.data?.startsAtFirstHalf || false}
                onChange={updateStartsAtFirstHalf}
              />
              <label />
            </div>
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Bis</h3>
            <Components.DateInput
              id={`${content?.id}-end`}
              value={content?.data?.end.format('DD.MM.YYYY') ?? ''}
              placeholder={placeholder}
              onChange={updateEnd}
              selected={content.data?.end.toDate()}
              minDate={moment().toDate()}
              className='editableinput'
            />
            <div className='editabletoggle'>
              <label>Reservierung bis Abends: </label>
              <Switch
                checked={content.data?.endsAtSecondHalf || false}
                onChange={updateEndsAtSecondHalf}
              />
              <label />
            </div>
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Gebucht von</h3>
            <Components.BasicInput
              id={`${content?.id}-reservedby`}
              value={content?.data?.reservedBy ?? ''}
              onChange={updateReservedBy}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              required
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>
              Kontaktdaten (Mail und/oder Telefonnummer)
            </h3>
            <h4>Mail</h4>
            <Components.BasicInput
              id={`${content?.id}-mail`}
              value={content?.data?.mail ?? ''}
              onChange={updateMail}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              regexp={AdminBookingChildContentService.mailRegexp}
            />
            <h4>Telefon</h4>
            <Components.BasicInput
              id={`${content?.id}-phone`}
              value={content?.data?.phone ?? ''}
              onChange={updatePhone}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              regexp={AdminBookingChildContentService.phoneRegexp}
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Veranstaltungsart</h3>
            <Components.BasicInput
              id={`${content?.id}-type`}
              value={content?.data?.type ?? ''}
              onChange={updateType}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              required
            />
          </div>
        </div>
      </main>
    );
  }
);
