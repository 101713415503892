import React from 'react';
import './index.css';
import { PersonType } from '../../types/person';
import { Orientation } from '../../types/orientation';
import { stateToHTML } from 'draft-js-export-html';
import parse from 'html-react-parser';

type Props = {
  person: PersonType;
  orientation: Orientation;
  id: string;
};

export const PersonInformation = ({ person, orientation, id }: Props) => {
  return (
    <div
      id={id}
      className={`personalInformation${
        orientation === 'Middle'
          ? ' openToMiddle'
          : orientation === 'Right'
          ? ''
          : ' openToLeft'
      }`}
    >
      <p className='c1'>
        {person.name}, {person.rank}
      </p>
      <p className='memberfunction c2'>{person.function}</p>
      <p className='alignment c1'>
        {parse(stateToHTML(person.information.getCurrentContent()))}
      </p>
    </div>
  );
};
