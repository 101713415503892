import React from 'react';
import { FaPlus } from 'react-icons/fa6';
import { observer } from 'mobx-react-lite';
import { AdminBookingContentService } from '../../../service/booking';
import { BookingChildNavigationSection } from './child';

interface BookingNavigationSectionProps {
  id: string;
  content: AdminBookingContentService;
}

export const BookingNavigationSection = observer(
  ({ id, content }: BookingNavigationSectionProps) => {
    const children = React.useMemo(
      () =>
        content.children?.slice().sort((left, right) => {
          if (!left.data || !right.data) return 1;
          return left.data.start.isBefore(right.data.start) ? -1 : 1;
        }),
      [content.children]
    );

    return (
      <div id={id} className='navigationsection'>
        <div className='navigationcontent'>
          <div className='leftpart'>
            <h3 className='sectionheader'>{content.id}</h3>
          </div>
          <div className='rightpart'>
            <FaPlus
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={content.addChild}
            />
          </div>
        </div>
        <div className='navigationchildren'>
          {children?.map((child) => (
            <BookingChildNavigationSection
              id={child.data?.id ?? child.id}
              key={child.data?.id ?? child.id}
              content={child}
              onRemove={content.removeChild}
            />
          ))}
        </div>
      </div>
    );
  }
);
