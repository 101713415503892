import React, { useEffect, useState } from 'react';
import './index.css';

type Props = { text: string; classNames?: string };

export const Textfield = ({ text, classNames }: Props) => {
  const [formattedText, setFormattedText] = useState<string>('');

  const formatText = (text: string) => {
    setFormattedText(
      text
        .replaceAll('\\n', '\n')
        .replaceAll('&#10;', '\n')
        .replaceAll('&#13;', '\n')
        .replaceAll('&#x0a;', '\n')
        .replaceAll('&#xa;', '\n')
        .replaceAll('&#x0000a;', '\n')
        .replaceAll('&#x0d;', '\n')
        .replaceAll('&#xd;', '\n')
        .replaceAll('&#x0000d;', '\n')
        .replaceAll('\\r', '\n')
        .replaceAll('<br>', '\n')
        .replaceAll('<br/>', '\n')
        .replaceAll('<br/>', '\n')
        .replaceAll('\t', '\n')
    );
  };

  useEffect(() => {
    formatText(text);
  }, [text]);

  return (
    <p className={`textfield${classNames ? classNames : ''}`}>
      {formattedText}
    </p>
  );
};
