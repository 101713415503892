import React from 'react';

import './index.css';

interface ButtonProps {
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
  className?: string;
}

export const Button = ({
  label,
  onClick,
  disabled,
  href,
  className,
}: ButtonProps) => {
  const handleAClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (disabled) return;
    if (onClick) onClick();
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (disabled) return;
    if (onClick) onClick();
  };

  return href ? (
    <a href={href} onClick={handleAClick}>
      <button
        className={
          disabled
            ? `commonbutton disabled ${className} c1`
            : `commonbutton ${className} c1`
        }
      >
        {label}
      </button>
    </a>
  ) : (
    <button
      onClick={handleButtonClick}
      className={
        disabled
          ? `commonbutton disabled ${className} c1`
          : `commonbutton ${className} c1`
      }
    >
      {label}
    </button>
  );
};
