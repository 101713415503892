import axios from 'axios';
import * as Storage from 'firebase/storage';
import { PreviewFile } from 'src/types/content';

export const updatePictures = async (
  ref: Storage.StorageReference,
  pictures?: (string | PreviewFile)[],
  preview?: string | PreviewFile
) => {
  const uploadPreview = async () => {
    try {
      if (!preview || typeof preview === 'string') return;
      var previewref = Storage.ref(ref, preview.file.name);
      await Storage.uploadBytes(previewref, preview.file);
    } catch (err) {
      console.log('preview', err);
    }
  };

  const uploadImgs = async () => {
    try {
      for (var img of pictures ?? []) {
        if (typeof img === 'string') continue;
        var imgref = Storage.ref(ref, img.file.name);
        await Storage.uploadBytes(imgref, img.file);
      }
    } catch (err) {
      console.log('imgs', err);
    }
  };

  await Promise.all([uploadImgs(), uploadPreview()]);
};

export const uploadPictures = async (
  ref: Storage.StorageReference,
  pictures?: (string | PreviewFile)[],
  preview?: string | PreviewFile
) => {
  const uploadPreview = async () => {
    try {
      if (!preview || typeof preview === 'string') return;
      var previewref = Storage.ref(ref, preview.file.name);
      await Storage.uploadBytes(previewref, preview.file);
    } catch (err) {
      console.log('preview', err);
    }
  };

  const uploadImgs = async () => {
    try {
      for (var img of pictures ?? []) {
        if (typeof img === 'string') continue;
        var imgref = Storage.ref(ref, img.file.name);
        await Storage.uploadBytes(imgref, img.file);
      }
    } catch (err) {
      console.log('imgs', err);
    }
  };

  await Promise.all([uploadImgs(), uploadPreview()]);
};

export const uploadPicture = async (
  ref: Storage.StorageReference,
  picture: string | PreviewFile
) => {
  try {
    if (typeof picture === 'string') return;
    var imgref = Storage.ref(ref, picture.file.name);
    await Storage.uploadBytes(imgref, picture.file);
  } catch (err) {
    console.log('imgs', err);
  }
};

export interface FileOptions {
  name: string;
  file: Blob;
}
