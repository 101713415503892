import React from 'react';
import { observer } from 'mobx-react-lite';

import { TeamEditable } from './team/editable';
import { StandardEditable } from './standard/editable';
import { AdminOverviewService } from '../../service/overview';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editable.css';
import { BookingsEditable } from './bookings/editable';
import { MerchandiseEditable } from './merchandise/editable';

export const Editable = observer(() => {
  const content = React.useMemo(
    () => AdminOverviewService.instance.selected,
    [AdminOverviewService.instance.selected]
  );

  if (content?.type === 'Team') return <TeamEditable content={content.data} />;

  if (content?.type === 'Bookings')
    return <BookingsEditable content={content.data} />;

  if (content?.type === 'Merchandise')
    return <MerchandiseEditable content={content.data} />;

  return <StandardEditable content={content?.data} />;
});
