import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import './input.css';

interface BasicInputProps {
  placeholder?: string;
  value: string;
  onChange: (value: string, valid: boolean) => void;
  errorHint?: string;
  className: string;
  type?: React.HTMLInputTypeAttribute;
  regexp?: RegExp;
  readonly?: boolean;
}

export const BasicInput = ({
  value,
  onChange,
  placeholder,
  className,
  type,
  regexp,
  readonly,
}: BasicInputProps) => {
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    const value = event.target.value;
    const error = !regexp?.test(value) ?? false;
    setError(error);
    onChange(value, !error);
  };

  const handleFocus = () => {
    if (readonly) return;
    setFocused(true);
  };

  return (
    <div className={`inputcontainer ${className}`}>
      <input
        value={value}
        onChange={handleChange}
        onFocusCapture={handleFocus}
        placeholder={placeholder}
        className='custominput'
        type={type}
        readOnly={readonly}
      />
      {focused && (
        <div className='hinticoncontainer'>
          {error ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className='hinticon error'
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='hinticon success'
            />
          )}
        </div>
      )}
    </div>
  );
};
