import React, { useEffect } from 'react';
import './index.css';
import { Link } from 'react-router-dom';

type Props = {
  pages: string[];
  showMenu: boolean;
  onClick: () => void;
};

export const Menu = ({ pages, showMenu, onClick }: Props) => {
  useEffect(() => {
    if (showMenu) {
      document.getElementsByClassName('menu')[0]?.classList.add('showMenu');
      return;
    }
    document.getElementsByClassName('menu')[0]?.classList.remove('showMenu');
  }, [showMenu]);

  return (
    <div className='menu centerFlex' id='mainMenu'>
      {pages.map((page, i) => {
        return (
          <div key={`menuItem${i}`}>
            <Link
              className='noLinkDecoration'
              onClick={onClick}
              to={`/${page
                .replaceAll('ä', 'ae')
                .replaceAll('ö', 'oe')
                .replaceAll('ü', 'ue')
                .replaceAll('ß', 'ss')}`}
            >
              <h4
                className={`menuPunkt ${
                  i === pages.length - 1 ? 'action' : ''
                }`}
              >
                {page}
              </h4>
            </Link>
            {i !== pages.length - 1 ? <div className='menuLine'></div> : null}
          </div>
        );
      })}
    </div>
  );
};
