import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { PreviewContent } from '../../types/content';

import { FirebaseImage } from '../image/firebase';
import { storage } from 'src/configuration';

import { convertToRaw } from 'draft-js';
import { Textfield } from '../Textfield';

import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

type Props = {
  caller: string;
  content: PreviewContent;
  numerator: number;
  loading?: boolean;
  width?: number | string;
  onClick: (content: PreviewContent) => void;
};

export const SmallArticle = ({
  caller,
  content,
  numerator,
  loading = false,
  width,
  onClick,
}: Props) => {
  const handleClick = () => onClick(content);

  const preview = React.useMemo(() => {
    var first = convertToRaw(content.text.getCurrentContent()).blocks[0].text;
    return first;
  }, [content]);

  return (
    <div className='smallArticle' onClick={handleClick}>
      <h2>
        {loading ? <Skeleton height={30} width={width} /> : content.header}
      </h2>
      <div className='smallArticleMainInfo'>
        {content.thumbnailPicture && (
          <FirebaseImage
            img={content.thumbnailPicture as string}
            storage={storage}
            className='smallArticlePhoto'
            storageprev={`${caller}/${content.id}`}
            loader={<Skeleton className='smallArticlePhotoLoader' />}
          />
        )}
        <div className='smallArticleContent'>
          {content.date && <p className='articleDate c2'>{content.date}</p>}
          {loading ? (
            <Skeleton count={4} />
          ) : (
            <Textfield
              text={preview}
              classNames={
                content.date
                  ? ' smallArticleContentTextWithDate c1'
                  : ' smallArticleContentText c1'
              }
            />
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <p className='author c2'>{content.author}</p>
          )}
        </div>
      </div>
    </div>
  );
};
