import React from 'react';
import { observer } from 'mobx-react-lite';
import Switch from 'react-switch';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import * as Components from 'src/administration/components';
import { AdminMerchandiseChildContentService } from '../../../service/merchandise';

import { AdminOverviewService } from '../../../service/overview';
import { PreviewFile } from 'src/types/content';
import { AddImage } from '../addimage';

import './editable.css';
import '../editable.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';

interface MerchandiseEditableProps {
  content: AdminMerchandiseChildContentService;
}

export const MerchandiseEditable = observer(
  ({ content }: MerchandiseEditableProps) => {
    const placeholder = React.useMemo(() => {
      if (content !== undefined) return 'Aktualisiere deinen Inhalt...';
      return 'Wählen Sie links den Inhalt, den Sie aktualisieren möchten...';
    }, [content]);

    const updateName = (value: string, _: boolean) => {
      if (!content.data) return;
      content.updateName(value);
    };

    const updateCosts = (value: string, _: boolean) => {
      if (!content.data) return;
      value = value.replace(/,/g, '.');

      content.updateCosts(value);
    };

    const updateHeight = (value: string, _: boolean) => {
      if (!content.data) return;
      value = value.replace(/,/g, '.');

      content.updateHeight(value);
    };

    const updateWidth = (value: string, _: boolean) => {
      if (!content.data) return;
      value = value.replace(/,/g, '.');

      content.updateWidth(value);
    };

    const updateLength = (value: string, _: boolean) => {
      if (!content.data) return;
      value = value.replace(/,/g, '.');

      content.updateLength(value);
    };

    const updateAvailable = (value: boolean) => {
      if (!content.data) return;
      content.updateAvailable(value);
    };

    const updateDescription = (value: EditorState) => {
      if (!content) return;
      content.updateDescription(value);
    };

    const addImg = (img: File) => {
      if (!content) return;
      content.addImg(img);
    };

    const removeImg = (img: string | PreviewFile) => {
      if (!content) return;
      content.removeImg(img);
    };

    const crop = (img: string | PreviewFile) => {
      if (!content) return;
      content.setCrop(img);
    };

    return (
      <main className='admineditablecontainer'>
        <div className='admineditable'>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Name</h3>
            <Components.BasicInput
              id={`${content?.id}-name`}
              value={content?.data?.name ?? ''}
              placeholder={placeholder}
              onChange={updateName}
              className='editableinput'
              required
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Kosten</h3>
            <Components.BasicInput
              id={`${content?.id}-costs`}
              value={content?.data?.costs.toString() ?? ''}
              placeholder={placeholder}
              onChange={updateCosts}
              className='editableinput'
              required
              regexp={AdminMerchandiseChildContentService.floatRegexp}
            />
          </div>
          <div className='editabletoggle'>
            <h3>Verfügbar</h3>
            <Switch
              checked={content.data?.available || false}
              onChange={updateAvailable}
            />
            <label />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Beschreibung</h3>
            <Editor
              editorState={content?.data?.description}
              onEditorStateChange={updateDescription}
              placeholder={placeholder}
              wrapperClassName='admineditorwrapper'
              toolbarClassName='admintoolbar'
              editorClassName='admineditor'
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Größe</h3>
            <div className='admineditablemulticomponent'>
              <h4>Länge(cm)</h4>
              <Components.BasicInput
                id={`${content?.id}-length`}
                value={content?.data?.size?.length ?? '0'}
                placeholder={placeholder}
                onChange={updateLength}
                className='editableinput'
                required
                regexp={AdminMerchandiseChildContentService.floatRegexp}
              />
              <h4>Höhe(cm)</h4>
              <Components.BasicInput
                id={`${content?.id}-height`}
                value={content?.data?.size?.height ?? '0'}
                placeholder={placeholder}
                onChange={updateHeight}
                className='editableinput'
                required
                regexp={AdminMerchandiseChildContentService.floatRegexp}
              />
              <h4>Breite(cm)</h4>
              <Components.BasicInput
                id={`${content?.id}-width`}
                value={content?.data?.size?.width ?? '0'}
                placeholder={placeholder}
                onChange={updateWidth}
                className='editableinput'
                required
                regexp={AdminMerchandiseChildContentService.floatRegexp}
              />
            </div>
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Fotos</h3>
            <div className='editablepicturepart'>
              <h4 className='pictureheader'>Alle</h4>
              <div className='editablepictures'>
                {content.data?.pictures
                  .slice()
                  .sort((left, right) => {
                    var leftString =
                      typeof left === 'string' ? left : left.file.name;
                    var rightString =
                      typeof right === 'string' ? right : right.file.name;

                    return leftString < rightString ? -1 : 1;
                  })
                  .map((img) => (
                    <Components.FirebaseImage
                      storageprev={`${content?.parent?.reduce(
                        (prev, curr) => prev + curr + '/',
                        ''
                      )}${content?.id}`}
                      key={typeof img === 'string' ? img : img.file.name}
                      img={img}
                      storage={AdminOverviewService.instance.storage!}
                      withTasks
                      onRemove={removeImg}
                      onCrop={crop}
                    />
                  ))}
                <AddImage
                  readonly={content === undefined}
                  onImageAdd={addImg}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
);
