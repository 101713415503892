import React from 'react';
import { FaPlus } from 'react-icons/fa6';

interface AddImageProps {
  readonly?: boolean;
  onImageAdd: (img: File) => void;
}

export const AddImage = ({ readonly, onImageAdd }: AddImageProps) => {
  const inputFile = React.useRef<HTMLInputElement>(null);

  const className = React.useMemo(() => {
    if (readonly) return 'addimagecontainer readonly';
    return 'addimagecontainer';
  }, [readonly]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length) {
      onImageAdd(files[0]);
      if (!inputFile.current) return;
      inputFile.current.value = '';
    }
  };

  const onClick = () => {
    if (readonly) return;
    inputFile.current?.click();
  };

  return (
    <div className={className} onClick={onClick}>
      <input
        style={{ display: 'none' }}
        accept='image/*'
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
      <FaPlus
        fontSize={26}
        color={readonly ? 'var(--Text50)' : 'var(--Text)'}
      />
    </div>
  );
};
