import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import * as Pages from 'src/Pages';
import * as Components from 'src/Components';
import * as Services from 'src/Services';

import './App.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  const location = useLocation();
  const pages = [
    'Home',
    'Beiträge',
    'Veranstaltungen',
    'Merchandise',
    'Team',
    'Anträge',
    'Kontakt',
  ];

  useEffect(() => {
    Services.MemberApi.reset();
  }, [location]);

  return (
    <div className='App'>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? 'NOT DEFINED'}
      >
        <Components.TopBar pages={pages} />
        <Services.AnalysisUI />
        <div className='topSpacer' />
        <div className='mainView'>
          <Routes>
            <Route index path='/' element={<Pages.Home />} />
            <Route path='/Beitraege' element={<Pages.Posts />} />
            <Route path='/Beitraege/:id' element={<Pages.DetailledPost />} />
            <Route path='/Veranstaltungen' element={<Pages.Happenings />} />
            <Route
              path='/Veranstaltungen/:id'
              element={<Pages.DetailledHappening />}
            />
            <Route path='/Merchandise' element={<Pages.MerchandisePage />} />
            <Route
              path='/Merchandise/:id'
              element={<Pages.MerchandiseDetail />}
            />
            <Route path='/Team' element={<Pages.TeamPage />} />
            <Route path='/Antraege' element={<Pages.RequestPage />} />
            <Route path='/Kontakt' element={<Pages.ContactPage />} />
            <Route path='/Satzung' element={<Pages.ClubStatutes />} />
            <Route path='/Impressum' element={<Pages.Impressum />} />
            <Route path='/Datenschutz' element={<Pages.Datenschutz />} />
            <Route path='*' element={<Pages.NotFound />} />
          </Routes>
        </div>
        <Components.Footer />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
