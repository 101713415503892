import React from 'react';
import { observer } from 'mobx-react-lite';

import { getOverview } from '../../network';
import { AdminOverviewService } from '../../service/overview';
import { PostNavigationSection } from './posts/parent';
import { TeamNavigationSection } from './team/parent';
import { HappeningsNavigationSection } from './happenings/parent';
import { BookingNavigationSection } from './bookings/parent';
import { MerchandiseNavigationSection } from './merchandise/parent';

import * as Components from 'src/administration/components';

import './navigation.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const Navigation = observer(() => {
  const init = React.useRef(false);
  const [loaded, setLoaded] = React.useState(false);

  const overview = React.useMemo(
    () => AdminOverviewService.instance.overview,
    [AdminOverviewService.instance.overview]
  );

  React.useEffect(() => {
    if (init.current) return;
    init.current = true;
    getOverview()
      .then(AdminOverviewService.setOverview)
      .finally(() => setLoaded(true));
  }, []);

  return (
    <aside className='adminnavigation'>
      {!loaded && <div className='loader'></div>}
      <div className='adminnavigationoverview'>
        {overview && loaded && (
          <DndProvider backend={HTML5Backend}>
            <PostNavigationSection
              id={overview.posts.id}
              key={overview.posts.id}
              content={overview.posts}
            />
            <HappeningsNavigationSection
              id={overview.happenings.id}
              key={overview.happenings.id}
              content={overview.happenings}
            />
            <TeamNavigationSection
              id={overview.team.id}
              key={overview.team.id}
              content={overview.team}
            />
            <BookingNavigationSection
              id={overview.booking.id}
              key={overview.booking.id}
              content={overview.booking}
            />
            <MerchandiseNavigationSection
              id={overview.merchandise.id}
              key={overview.merchandise.id}
              content={overview.merchandise}
            />
          </DndProvider>
        )}
      </div>
      <div className='adminnavigationbuttons'>
        <Components.SubmitButton
          label='Speichern'
          onSubmit={AdminOverviewService.save}
        />
      </div>
    </aside>
  );
});
