import React from 'react';
import { observer } from 'mobx-react-lite';
import { FaEye } from 'react-icons/fa6';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import * as Components from 'src/administration/components';
import { FirebaseImage } from 'src/administration/components/image/firebase';
import { AdminOverviewService } from '../../../service/overview';
import { AdminPostChildContentService } from '../../../service/post';
import { ImageCrop } from '../../image/crop';
import { AddImage } from '../addimage';
import { AdminHappeningsChildContentService } from '../../../service/happenings';

import { PreviewFile } from 'src/types/content';

import '../editable.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface StandardEditableProps {
  content?: AdminHappeningsChildContentService | AdminPostChildContentService;
}

export const StandardEditable = observer(
  ({ content }: StandardEditableProps) => {
    const placeholder = React.useMemo(() => {
      if (content !== undefined) return 'Aktualisiere deinen Inhalt...';
      return 'Wählen Sie links den Inhalt, den Sie aktualisieren möchten...';
    }, [content]);

    const preview = React.useMemo(() => {
      if (!content) return;
      if (!content.data?.pictures) return;
      return content.data?.thumbnailPicture;
    }, [content?.data?.thumbnailPicture]);

    const pictures = React.useMemo(() => {
      var pics: (string | PreviewFile)[] = [];

      if (content?.data?.thumbnailPicture) {
        pics = [content.data.thumbnailPicture];
      }

      if (content?.data?.pictures) {
        pics = [...pics, ...content.data.pictures];
      }

      return pics;
    }, [content?.data?.pictures, content?.data?.thumbnailPicture]);

    const updateTitle = (value: string, valid: boolean) => {
      if (!content) return;
      content.updateTitle(value, valid);
    };

    const updateText = (value: EditorState) => {
      if (!content) return;
      content.updateText(value, true);
    };

    const updateAuthor = (value: string, valid: boolean) => {
      if (!content) return;
      content.updateAuthor(value, valid);
    };

    const setThumbnail = (img: string | PreviewFile) => {
      if (!content) return;
      content.setThumbnail(img);
    };

    const addImg = (img: File) => {
      if (!content) return;
      content.addImg(img);
    };

    const removeImg = (img: string | PreviewFile) => {
      if (!content) return;
      content.removeImg(img);
    };

    const crop = (img: string | PreviewFile) => {
      if (!content) return;
      content.setCrop(img);
    };

    return (
      <main className='admineditablecontainer'>
        <ImageCrop />
        <div className='admineditable'>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Titel</h3>
            <Components.BasicInput
              id={`${content?.id}-title`}
              value={content?.data?.header ?? ''}
              onChange={updateTitle}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              required
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Author</h3>
            <Components.BasicInput
              id={`${content?.id}-author`}
              value={content?.data?.author ?? ''}
              onChange={updateAuthor}
              className='editableinput'
              placeholder={placeholder}
              readonly={content === undefined}
              required
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Text</h3>
            <Editor
              editorState={content?.data?.text}
              onEditorStateChange={updateText}
              placeholder={placeholder}
              wrapperClassName='admineditorwrapper'
              toolbarClassName='admintoolbar'
              editorClassName='admineditor'
            />
          </div>
          <div className='admineditablecomponent'>
            <h3 className='editabletitle'>Fotos</h3>
            <div className='editablepicturecontainer'>
              <div className='editablepicturepart'>
                <h4 className='pictureheader'>
                  Vorschau
                  <FaEye
                    fontSize={18}
                    className='sectionicon'
                    color='var(--Text)'
                  />
                </h4>
                {preview && (
                  <FirebaseImage
                    storage={AdminOverviewService.instance.storage!}
                    img={preview}
                    onRemove={removeImg}
                    storageprev={`${content?.parent?.reduce(
                      (prev, curr) => prev + curr + '/',
                      ''
                    )}${content?.id}`}
                  />
                )}
              </div>
              <div className='editablepicturepart'>
                <h4 className='pictureheader'>Alle</h4>
                <div className='editablepictures'>
                  {pictures
                    .sort((left, right) => {
                      var leftString =
                        typeof left === 'string' ? left : left.file.name;
                      var rightString =
                        typeof right === 'string' ? right : right.file.name;

                      return leftString < rightString ? -1 : 1;
                    })
                    .map((img) => (
                      <FirebaseImage
                        storageprev={`${content?.parent?.reduce(
                          (prev, curr) => prev + curr + '/',
                          ''
                        )}${content?.id}`}
                        key={typeof img === 'string' ? img : img.file.name}
                        img={img}
                        storage={AdminOverviewService.instance.storage!}
                        withTasks
                        isThumbnail={img === preview}
                        onRemove={removeImg}
                        onChangeToThumbnail={setThumbnail}
                        onCrop={crop}
                      />
                    ))}
                  <AddImage
                    readonly={content === undefined}
                    onImageAdd={addImg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
);
