import { makeAutoObservable } from 'mobx';
import * as Store from 'firebase/firestore';
import * as Storage from 'firebase/storage';
import { NetworkRemoveType } from '../../domain/network';
import { AdminOverview } from '../../domain/overview';
import { AdminUsableOverview } from './type';
import { AdminPreviewContentService } from './preview';
import { AdminPostChildContentService, AdminPostContentService } from './post';
import { AdminTeamChildContentService, AdminTeamContentService } from './team';
import {
  AdminHappeningsChildContentService,
  AdminHappeningsContentService,
} from './happenings';
import {
  AdminBookingChildContentService,
  AdminBookingContentService,
} from './booking';
import {
  AdminMerchandiseChildContentService,
  AdminMerchandiseContentService,
} from './merchandise';

export type SelectionType =
  | TeamSelection
  | PostSelection
  | HappeningsSelection
  | BookingsSelection
  | MerchandiseSelection;

export interface TeamSelection {
  data: AdminTeamChildContentService;
  type: 'Team';
}
export interface PostSelection {
  data: AdminPostChildContentService;
  type: 'Post';
}
export interface HappeningsSelection {
  data: AdminHappeningsChildContentService;
  type: 'Happenings';
}

export interface BookingsSelection {
  data: AdminBookingChildContentService;
  type: 'Bookings';
}

export interface MerchandiseSelection {
  data: AdminMerchandiseChildContentService;
  type: 'Merchandise';
}

export class AdminOverviewService {
  private static innerinstance: AdminOverviewService =
    new AdminOverviewService();

  static get instance() {
    return this.innerinstance;
  }

  private _overview: AdminUsableOverview | undefined;
  private _selected?: SelectionType;
  private _removed: NetworkRemoveType[] = [];

  store: Store.Firestore | undefined;
  storage: Storage.FirebaseStorage | undefined;

  get overview(): AdminUsableOverview | undefined {
    return this._overview;
  }

  get selected() {
    return this._selected;
  }

  set overview(value: AdminUsableOverview | undefined) {
    this._overview = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  private initialize(store: Store.Firestore, storage: Storage.FirebaseStorage) {
    this.store = store;
    this.storage = storage;
  }

  private set(overview: AdminOverview) {
    this.overview = {
      posts: AdminPostContentService.fromEditableContent(overview.posts),
      happenings: AdminHappeningsContentService.fromEditableContent(
        overview.happenings
      ),
      team: AdminTeamContentService.fromEditableContent(overview.team),
      booking: AdminBookingContentService.fromEditableContent(
        overview.bookings
      ),
      merchandise: AdminMerchandiseContentService.fromEditableContent(
        overview.merchandise
      ),
    };
  }

  private setSelected(value?: SelectionType) {
    if (this._selected === value) this._selected = undefined;
    else this._selected = value;
  }

  private registerRemoved(value: AdminPreviewContentService) {
    this._removed = [
      ...this._removed,
      { id: value.id, collection: (value.parent ?? [''])[0] },
    ];
  }

  static initialize = (
    store: Store.Firestore,
    storage: Storage.FirebaseStorage
  ) => AdminOverviewService.instance.initialize(store, storage);

  static setOverview = (overview: AdminOverview) =>
    AdminOverviewService.instance.set(overview);

  static setSelected = (value?: SelectionType) =>
    AdminOverviewService.instance.setSelected(value);

  static registerRemoved = (value: AdminPreviewContentService) =>
    AdminOverviewService.instance.registerRemoved(value);

  static async save() {
    try {
      if (AdminOverviewService.instance.overview) {
        await Promise.all([
          AdminOverviewService.instance.overview.posts.save(),
          AdminOverviewService.instance.overview.team.save(),
          AdminOverviewService.instance.overview.booking.save(),
          AdminOverviewService.instance.overview.happenings.save(),
          AdminOverviewService.instance.overview.merchandise.save(),
        ]);
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static cancel() {
    return true;
  }
}
