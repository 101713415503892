import React from 'react';

import './components.css';
import { useFileRequest } from 'src/Network/fetchAPIs';
import { storage } from 'src/configuration';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import { SubmitButton } from 'src/administration/components';
import { BiSolidDownload } from 'react-icons/bi';

export const MemberRequest = () => {
  const supportMail = React.useRef('info@btg-manching.de').current;
  const mailBody = React.useRef(
    `Guten Tag, \n\ngerne möchte ich mit dem beigefügten Formular die Mitgliedschaft in Ihrem Verein beantragen. \n\nMit freundlichen Grüßen,\n\n`
  ).current;

  const [mitgliedsantrag, setMitgliedsantrag] = React.useState<string>();
  const [loading, setLoading] = React.useState(true);

  const [getMitgliedsantrag] = useFileRequest(
    storage,
    'Mitgliedsantrag.pdf',
    'Dokumente'
  );

  React.useEffect(() => {
    getMitgliedsantrag()
      .then(setMitgliedsantrag)
      .catch((error) => {
        AnalysisApi.log(AnalysisEvent.ERROR, {
          error: `load mitgliedsantrag: ${error}`,
        });
      })
      .finally(() => setLoading(false));
  }, []);

  function download() {
    if (!mitgliedsantrag) return false;
    const a = document.createElement('a');
    a.href = mitgliedsantrag;
    a.download = 'Mitgliedsantrag.pdf';
    const clickHandler = () => {
      setTimeout(() => {
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();

    return true;
  }

  return (
    <div className='requestpart'>
      <h3>Mitgliedsantrag</h3>
      <div className='alignment c1'>
        <p>
          Für die Beantragung zur Aufnahme als Mitglied des Vereins muss der
          Mitgliedsantrag ausgefüllt, unterschrieben und an uns zurückgesandt
          werden.{' '}
        </p>
        <p>
          Der Antrag kann per Post an die auf dem Mitgliedsantrag versehene
          Adresse zu Händen Christian Geisler oder per E-Mail an{' '}
          <a
            href={`mailto:${supportMail}?subject=Mitgliedsantrag&body=${encodeURIComponent(
              mailBody
            )}`}
            className='hrefitalic'
          >
            info@btg-manching.de
          </a>{' '}
          gesendet werden.
        </p>
        <p>Sie erhalten von uns innerhalb kürzester Zeit eine Rückmeldung.</p>
      </div>
      <div className='alignment c1'>
        <p>
          Ob das Kreuz bei 'ordentliches Mitglied' oder 'außerordentliches
          Mitglied' gesetzt werden muss, kann aus der Vereinssatzung §4
          entnommen werden.
        </p>
      </div>
      <div className='getmemberrequestcontainer'>
        <SubmitButton
          label='Mitgliedsantrag herunterladen'
          onSubmit={download}
          disabled={mitgliedsantrag === undefined}
          className='getmemberrequest'
          withLeftElement={
            loading ? (
              <div className='downloadloader'>
                <BiSolidDownload color='var(--Text)' size={20} />
              </div>
            ) : undefined
          }
        />
      </div>
    </div>
  );
};
