import React from 'react';
import { Link } from 'react-router-dom';

import './topbar.css';

interface TopBarProps {
  extras?: JSX.Element[];
}

export const TopBar = ({ extras }: TopBarProps) => {
  return (
    <div className='topbarcontainer'>
      <div className='topbar'>
        <Link to='/' className='topbaricon'>
          <img className='topbaricon' src='/BTG_Final.png' alt='BtgFinal' />
        </Link>
        {extras}
      </div>
    </div>
  );
};
