import axios from 'axios';
import * as Storage from 'firebase/storage';
import { uploadPicture } from '../common/crud';
import { AdminOverviewService } from '../../service/overview';
import { AdminTeamChildContentService } from '../../service/team';
import {
  FirebasePersonType,
  mapFirebasePTToPT,
  mapPTToFirebasePT,
  PersonType,
} from 'src/types/person';
import { EditableContent } from '../../../domain/content';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import { AdminAgent } from 'src/administration/contracts/agent';

export class TeamNetwork {
  static list = async (): Promise<EditableContent<PersonType>> => {
    const content = await axios.get<FirebasePersonType[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/team'
    );

    if (content.status !== 200) {
      throw new Error('Something went wrong on the server');
    }

    const filteredContent = content.data.filter(
      (dt) => dt.id !== 'Ansprechpartner'
    );

    return {
      id: 'Team',
      isMovable: false,
      isRemovable: false,
      children: filteredContent
        .sort((left, right) => (left.position > right.position ? -1 : 1))
        .map((element) => ({
          id: element.id,
          data: mapFirebasePTToPT(element),
          isMovable: true,
          isRemovable: true,
          parent: ['Team'],
        })),
    };
  };

  static update = async (content?: AdminTeamChildContentService[]) => {
    if (!content) return;
    if (!AdminOverviewService.instance.storage) return;

    for (var child of content) {
      if (!child.data) continue;

      const data = mapPTToFirebasePT(child.data);

      try {
        var ref = Storage.ref(
          AdminOverviewService.instance.storage,
          `Team/${child.id}`
        );

        if (child.data.picture) await uploadPicture(ref, child.data.picture);

        if (child.isNew)
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/team`,
            data
          );
        else
          await AdminAgent.update(
            `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/team/${child.id}`,
            data
          );

        child.isUploaded();
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while updating booking ${child}, ${err}`
        );
      }
    }
  };

  static delete = async (content: string[]) => {
    if (content.length === 0) return;

    for (var child of content) {
      try {
        await AdminAgent.delete(
          `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/team/${child}/delete`
        );
      } catch (err) {
        AnalysisApi.log(
          AnalysisEvent.ERROR,
          `Error while deleting booking ${child}, ${err}`
        );
      }
    }
  };
}
