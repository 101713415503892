import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { PhotoSlider } from '../PhotoSlider';
import { PreviewContent } from '../../types/content';
import { stateToHTML } from 'draft-js-export-html';
import parse from 'html-react-parser';

import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

type Props = {
  content: PreviewContent;
  numerator?: number;
  caller: string;
  additionalContent?: JSX.Element;
  loading?: boolean;
};

export const Article = ({
  content,
  numerator,
  caller,
  additionalContent,
  loading = false,
}: Props) => {
  return (
    <div className='section' key={`section${numerator}`}>
      <div className='sectionContent'>
        <h2>{loading ? <Skeleton height={30} /> : content.header}</h2>
        {additionalContent}
        {loading ? (
          <Skeleton count={10} />
        ) : (
          <div className='alignment c1'>
            {parse(stateToHTML(content.text.getCurrentContent()))}
          </div>
        )}
      </div>
      {content.pictures && content.pictures.length > 0 && (
        <PhotoSlider
          id={content.id}
          photoSliderId={`${caller}PhotoSlider${numerator}`}
          caller={caller}
          pictures={content.pictures as string[]}
        />
      )}
      {content.author ? (
        <div className='sectionAuthor'>
          <p className='author c2'>{content.author}</p>
        </div>
      ) : null}
    </div>
  );
};
