export class AppStorage {
  static instance: AppStorage = new AppStorage();

  private storage: Map<string, any> = new Map();

  setItem = <T>(key: string, value: T) => {
    this.storage.set(key, value);
  };

  getItem = <T>(key: string) => {
    if (!this.storage.has(key)) return null;
    var item = this.storage.get(key);
    return item as T;
  };

  reset = () => {
    this.storage = new Map();
  };

  static setItem = <T>(key: string, value: T) => {
    this.instance.setItem(key, value);
  };

  static getItem = <T>(key: string) => this.instance.getItem<T>(key);

  static reset = () => this.instance.reset();
}
