import axios from 'axios';
import { AuthorizationState } from '../state/authorization';

export class AdminAgent {
  static update = async <T>(url: string, config: T) => {
    const token = await AuthorizationState.token();

    await axios.post(url, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  static delete = async (url: string) => {
    const token = await AuthorizationState.token();

    await axios.post(url, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
