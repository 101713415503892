import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';

import * as Components from 'src/administration/components';
import * as Modules from 'src/administration/modules';
import { AuthorizationState } from '../state/authorization';
import { observer } from 'mobx-react-lite';

import './page.css';

interface AdminPageProps {
  firestore: Firestore;
  storage: FirebaseStorage;
}

export const AdminPage = observer(({ ...props }: AdminPageProps) => {
  const navigate = useNavigate();

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    AuthorizationState.listen(() => setInitialized(true));
  }, []);

  React.useEffect(() => {
    if (AuthorizationState.isLoggedIn) navigate('/admin/verify');
    else navigate('/admin/login');
  }, [AuthorizationState.isLoggedIn]);

  const logout = async () => {
    await AuthorizationState.logOut();
    return true;
  };

  return (
    <main>
      <Components.TopBar
        extras={
          AuthorizationState.isLoggedIn
            ? [
                <Components.SubmitButton
                  label='Ausloggen'
                  onSubmit={logout}
                  key='logoutbutton'
                />,
              ]
            : []
        }
      />
      <div className='spacer' />
      {!initialized && <Components.PageLoader />}
      <div className='admincontent'>
        <Routes>
          <Route
            path='*'
            element={
              <Components.ProtectedRoute
                redirect='/admin/login'
                redirectIf={!AuthorizationState.isLoggedIn}
              >
                <Modules.Dashboard {...props} />
              </Components.ProtectedRoute>
            }
          />
          <Route path='/login' element={<Modules.LoginUI />} />
        </Routes>
      </div>
    </main>
  );
});
