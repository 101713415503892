import React from 'react';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './input.css';

interface BasicInputProps {
  id: string;
  placeholder?: string;
  value: string;
  onChange?: (value: string, valid: boolean) => void;
  onClick?: () => void;
  errorHint?: string;
  className: string;
  type?: React.HTMLInputTypeAttribute;
  regexp?: RegExp;
  readonly?: boolean;
  required?: boolean;
}

export const BasicInput = ({
  id,
  value,
  onChange,
  onClick,
  placeholder,
  className,
  type,
  regexp,
  readonly,
  required,
}: BasicInputProps) => {
  const ref = React.useRef('');
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    const value = event.target.value;
    const error = checkForError(value);
    onChange && onChange(value, !error);
  };

  const handleFocus = () => {
    if (readonly) return;
    setFocused(true);
    checkForError(value);
  };

  const checkForError = (value: string) => {
    let error = regexp !== undefined ? !regexp.test(value) : false;
    if (required && value.trimStart().length === 0) {
      error = true;
    }
    setError(error);
    return error;
  };

  React.useEffect(() => {
    if (ref.current === id) return;

    ref.current = id;
    setFocused(false);
    setError(true);
  }, [id]);

  return (
    <div className={`inputcontainer ${className}`} onClick={onClick}>
      <input
        value={value}
        onChange={handleChange}
        onFocusCapture={handleFocus}
        placeholder={placeholder}
        className='custominput'
        type={type}
        readOnly={readonly}
      />
      {focused && (
        <div className='hinticoncontainer'>
          {error ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className='hinticon error'
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='hinticon success'
            />
          )}
        </div>
      )}
    </div>
  );
};
