import axios from 'axios';
import { AnalysisApi, AnalysisEvent } from 'src/Services';
import {
  FirebasePreviewContent,
  mapFirebasePCToPC,
  PreviewContent,
} from 'src/types/content';

export const getHappenings = async (): Promise<PreviewContent[]> => {
  try {
    const response = await axios.get<FirebasePreviewContent[]>(
      'https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening'
    );

    const data = response.data.map(mapFirebasePCToPC);

    return data.sort((left, right) =>
      left.position > right.position ? -1 : 1
    );
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, { error: `getHappenings: ${error}` });
    return [];
  }
};

export const getSpecificHappening = async (
  name: string
): Promise<PreviewContent | undefined> => {
  try {
    const response = await axios.get<FirebasePreviewContent>(
      `https://us-central1-btg-wuz-1-ev.cloudfunctions.net/happening/search/${name}`
    );

    const data = mapFirebasePCToPC(response.data);

    return data;
  } catch (error) {
    AnalysisApi.log(AnalysisEvent.ERROR, {
      error: `getSpecificHappening: ${error}`,
    });
    return undefined;
  }
};
