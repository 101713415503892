import moment from 'moment';

export interface FirebaseAdminBuchung {
  id: string;
  start: string;
  end: string;
  mail: string;
  phone: string;
  reservedBy: string;
  type: string;
  startsAtFirstHalf?: boolean;
  endsAtSecondHalf?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface AdminBuchung {
  id: string;
  start: moment.Moment;
  end: moment.Moment;
  startsAtFirstHalf?: boolean;
  endsAtSecondHalf?: boolean;
  reservedBy: string;
  mail: string;
  phone: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
}

export const DefaultBuchung: AdminBuchung = {
  id: '',
  start: moment(),
  end: moment(),
  startsAtFirstHalf: false,
  endsAtSecondHalf: false,
  reservedBy: '',
  mail: '',
  phone: '',
  type: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mapFirebaseAdminBuchungToAdminBuchung = (
  firebase: FirebaseAdminBuchung
): AdminBuchung => ({
  id: firebase.id,
  start: moment(firebase.start),
  end: moment(firebase.end),
  reservedBy: firebase.reservedBy,
  type: firebase.type,
  mail: firebase.mail,
  phone: firebase.phone,
  startsAtFirstHalf: firebase.startsAtFirstHalf,
  endsAtSecondHalf: firebase.endsAtSecondHalf,
  createdAt: new Date(firebase.createdAt),
  updatedAt: new Date(firebase.updatedAt),
});

export const mapAdminBuchungToFirebaseAdminBuchung = (
  buchung: AdminBuchung
): FirebaseAdminBuchung => ({
  id: buchung.id,
  start: buchung.start.toISOString(),
  end: buchung.end.toISOString(),
  reservedBy: buchung.reservedBy,
  type: buchung.type,
  mail: buchung.mail,
  phone: buchung.phone,
  startsAtFirstHalf: buchung.startsAtFirstHalf,
  endsAtSecondHalf: buchung.endsAtSecondHalf,
  createdAt: buchung.createdAt.toISOString(),
  updatedAt: buchung.updatedAt.toISOString(),
});
