import { collection, getDocs } from '@firebase/firestore';
import {
  FirebasePreviewContent,
  mapFirebasePCToPC,
  PreviewContent,
} from '../types/content';
import { getDownloadURL, ref } from '@firebase/storage';
import React from 'react';
import {
  Contacts,
  FirebasePersonType,
  mapFirebasePTToPT,
  PersonType,
} from '../types/person';
import { FirebaseStorage, StorageReference } from 'firebase/storage';
import { doc, getDoc, query, where } from 'firebase/firestore';
import { AppStorage } from '../Storage';
import { AnalysisApi } from '../Services/analysis/api';
import { AnalysisEvent } from '../Services/analysis/domain/events';
import { store, storage } from '../configuration';

const getPictureUrl = async (pictureRef: StorageReference) => {
  try {
    return await getDownloadURL(pictureRef);
  } catch (error: any) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break;
    }
    return '';
  }
};

const downloadFile = async (
  fileRef: StorageReference,
  name: string
): Promise<File | undefined> => {
  var url = await getPictureUrl(fileRef);

  if (url === '') return;

  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const file = new File([xhr.response], name);
      resolve(file);
    };
    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.open('GET', url);
    xhr.send();
  });
};

export const usePreviewRequest = (
  calledCollection: string,
  sortingAsc: boolean = true
) => {
  const fetcher = React.useCallback(
    (): Promise<PreviewContent[]> =>
      new Promise(async (resolve, reject) => {
        var stored =
          AppStorage.getItem<FirebasePreviewContent[]>(calledCollection);
        if (stored !== null) {
          var mapped = stored.map(mapFirebasePCToPC);
          resolve(mapped);
          return;
        }
        try {
          const snapshot = await getDocs(collection(store, calledCollection));
          const preview = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })) as FirebasePreviewContent[];

          sortingAsc
            ? preview.sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1))
            : preview.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

          AppStorage.setItem(calledCollection, preview);
          var mapped = preview.map(mapFirebasePCToPC);
          resolve(mapped);
        } catch (error: any) {
          AnalysisApi.log(AnalysisEvent.ERROR, {
            error: `usePreviewRequest: ${calledCollection}, ${error}`,
          });
          reject(error);
        }
      }),
    [calledCollection, sortingAsc]
  );

  return [fetcher];
};

export const useContentRequest = (content: string, from: string) => {
  const fetcher = React.useCallback(
    (): Promise<PreviewContent | undefined> =>
      new Promise(async (resolve, reject) => {
        var stored = AppStorage.getItem<FirebasePreviewContent[]>(from);
        if (stored !== null) {
          var item = stored.find((preview) => preview.header === content);
          if (item) {
            var mapped = mapFirebasePCToPC(item);
            resolve(mapped);
            return;
          }
        }

        try {
          const fromRef = collection(store, from);
          const q = query(fromRef, where('header', '==', content));
          const snapshot = await getDocs(q);
          const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })) as FirebasePreviewContent[];

          if (!data || data.length === 0) {
            resolve(undefined);
            return;
          }

          const event = data.at(0);

          if (!event) return;

          var mapped = mapFirebasePCToPC(event);
          resolve(mapped);
        } catch (error: any) {
          AnalysisApi.log(AnalysisEvent.ERROR, {
            error: `useContentRequest: ${content}, ${from}, ${error}`,
          });
          reject(error);
        }
      }),
    [content, from]
  );

  return [fetcher];
};

export const usePictureRequest = (
  calledCollection: string,
  calledDocument: string
) => {
  const fetcher = React.useCallback((): Promise<string[]> => {
    return new Promise(async (resolve, reject) => {
      var stored = AppStorage.getItem<string[]>(`${calledDocument}-images`);
      if (stored !== null) {
        resolve(stored);
        return;
      }

      try {
        var imgs: string[] = [];
        const snapshot = await getDocs(collection(store, calledCollection));
        const contentData = snapshot.docs
          .find((doc) => doc.id === calledDocument)
          ?.get('pictures') as string[];

        if (!contentData) {
          resolve([]);
          return;
        }

        await Promise.all(
          contentData.map(async (picture) => {
            const pictureRef = ref(storage, picture);
            var file = await downloadFile(pictureRef, picture);
            if (file) {
              var urlCreator = window.URL || window.webkitURL;
              const fileUrl = urlCreator.createObjectURL(file);
              imgs.push(fileUrl);
            }
          })
        );

        AppStorage.setItem(`${calledDocument}-images`, imgs);
        resolve(imgs);
      } catch (error) {
        AnalysisApi.log(AnalysisEvent.ERROR, {
          error: `usePictureRequest: ${calledCollection}, ${calledDocument}, ${error}`,
        });
        reject();
      }
    });
  }, [calledCollection, calledDocument]);

  return [fetcher];
};

export const useFileRequest = (
  storage: FirebaseStorage,
  file: string,
  previous?: string
) => {
  const fetcher = React.useCallback((): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      var filename = previous ? `${previous}/${file}` : file;

      var stored = AppStorage.getItem<string>(filename);
      if (stored !== null) {
        resolve(stored);
        return;
      }

      try {
        var img: string = '';

        const pictureRef = ref(storage, filename);
        var download = await downloadFile(pictureRef, filename);
        if (download) {
          var urlCreator = window.URL || window.webkitURL;
          img = urlCreator.createObjectURL(download);
        }

        AppStorage.setItem(filename, img);
        resolve(img);
      } catch (error) {
        AnalysisApi.log(AnalysisEvent.ERROR, {
          error: `useFileRequest: ${filename}, ${error}`,
        });
        reject();
      }
    });
  }, [file, previous, storage]);

  return [fetcher];
};

export const useTeamRequest = (caller: string) => {
  const fetcher = React.useCallback((): Promise<PersonType[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        var persons = AppStorage.getItem<Contacts>('Ansprechpartner');
        const team: PersonType[] = [];
        if (!persons) {
          const snapshot = await getDoc(
            doc(collection(store, 'Team'), 'Ansprechpartner')
          );
          persons = snapshot.data() as Contacts;
        }
        const array = getPersonAssignee(persons, caller);

        if (!persons) return;

        for (var contact of array) {
          var personstored = AppStorage.getItem<FirebasePersonType>(contact);
          if (personstored !== null) {
            var mapped = mapFirebasePTToPT(personstored);
            team.push(mapped);
            continue;
          }
          const personsnapshot = await getDoc(
            doc(collection(store, 'Team'), contact)
          );
          const person = {
            ...personsnapshot.data(),
            id: personsnapshot.id,
          } as FirebasePersonType;
          AppStorage.setItem(contact, person);
          mapped = mapFirebasePTToPT(person);
          team.push(mapped);
        }

        AppStorage.setItem('Ansprechpartner', persons);
        resolve(team);
      } catch (error) {
        AnalysisApi.log(AnalysisEvent.ERROR, {
          error: `useTeamRequest: Ansprechpartner, ${error}`,
        });
        reject(error);
      }
    });
  }, []);

  return [fetcher];
};

const getPersonAssignee = (persons: Contacts, caller: string) => {
  if (caller === 'Mitgliederanträge') return persons.mitgliederanträge;
  else if (caller === 'Vorstand') return persons.vorstand;
  return [];
};
