import React from 'react';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface CancelButtonProps {
  label: string | JSX.Element;
  onCancel: () => Promise<boolean> | boolean;
  disabled?: boolean;
  href?: string;
  className?: string;
}

export const CancelButton = ({ onCancel, ...props }: CancelButtonProps) => {
  const [success, setSuccess] = React.useState<boolean>();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    if (props.disabled) return;
    setLoading(true);
    var success = await onCancel();
    setTimeout(() => {
      setLoading(false);
      setSuccess(success);
      setTimeout(() => setSuccess(undefined), 2000);
    }, 250);
  };

  const overlayClassnameExtra = React.useMemo(() => {
    if (!loading && success === undefined) return 'overlay inverted';
    if (loading) return 'overlay inverted loading';
    if (success === true) return 'overlay inverted success';
    if (success === false) return 'overlay inverted error';
  }, [success, loading]);

  return (
    <div className='cancelbutton'>
      <Button onClick={handleClick} inverted {...props} />
      <div className={overlayClassnameExtra}>
        {loading && <div className='loader'></div>}
        {success === true && (
          <div className='success'>
            <FontAwesomeIcon icon={faCheck} size='xl' />
          </div>
        )}
        {success === false && (
          <div className='error'>
            <FontAwesomeIcon icon={faXmark} size='xl' />
          </div>
        )}
      </div>
    </div>
  );
};
