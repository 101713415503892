import React from 'react';
import { useParams } from 'react-router-dom';
import { MerchDetail } from './application/components/details';
import { DefaultMerchandise, Merchandise } from './domain/merchandise';
import { getSpecificMerchandise } from './application/network/read';

export const MerchandiseDetail = () => {
  const { id } = useParams();
  const init = React.useRef(false);
  const [merch, setMerch] = React.useState<Merchandise>(DefaultMerchandise);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (init.current || !id) return;
    init.current = true;

    getSpecificMerchandise(id.replace(/_/g, ' ')).then((merch) => {
      if (merch) setMerch(merch);
      setLoading(false);
    });
  }, []);

  return (
    <div className='content'>
      <MerchDetail merchandise={merch} loading={loading} />
    </div>
  );
};
