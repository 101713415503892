import { makeAutoObservable } from 'mobx';

export class MemberService {
  public static readonly instance: MemberService = new MemberService();

  private Member: string | undefined = undefined;

  setMember = (newId: string = '') => {
    if (newId === '' || newId === this.Member) this.Member = undefined;
    else this.Member = newId;
  };

  constructor() {
    makeAutoObservable(this);
  }

  getMember = () => {
    return this.Member;
  };

  clear = () => (this.Member = undefined);

  static readonly reset = () => MemberService.instance.clear();
}
