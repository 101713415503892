import React from 'react';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => navigate('/'), 5000);
  });

  return (
    <div>
      <h1>Diese Seite wurde nicht gefunden</h1>
      <h2>Du wirst in wenigen Sekunden zur Homepage zurückgeleitet</h2>
    </div>
  );
};
