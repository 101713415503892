import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { PreviewFile } from './content';

export type FirebasePersonType = {
  id: string;
  name: string;
  rank: string;
  picture: string;
  function: string;
  createdAt: string;
  updatedAt: string;
  information: string;
  position: number;
};

export type PersonType = {
  id: string;
  name: string;
  rank: string;
  picture?: string | PreviewFile;
  function: string;
  createdAt: Date;
  updatedAt: Date;
  information: EditorState;
  position: number;
};

export const DefaultPersonType: PersonType = {
  id: '',
  name: '',
  rank: '',
  picture: '',
  function: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  information: EditorState.createEmpty(),
  position: 0,
};

export type Contacts = {
  mitgliederanträge: string[];
  vorstand: string[];
};

export const mapFirebasePTToPT = (data: FirebasePersonType): PersonType => ({
  id: data.id,
  name: data.name,
  rank: data.rank,
  picture: data.picture,
  function: data.function,
  information: EditorState.createWithContent(
    convertFromRaw(JSON.parse(data.information))
  ),
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
  position: data.position,
});

export const mapPTToFirebasePT = (data: PersonType): FirebasePersonType => ({
  id: data.id,
  name: data.name,
  rank: data.rank,
  picture: data.picture
    ? mapStringOrPreviewFilePictureToStringPicture(data.picture)
    : '',
  function: data.function,
  information: JSON.stringify(
    convertToRaw(data.information.getCurrentContent())
  ),
  position: data.position,
  createdAt: data.createdAt.toISOString(),
  updatedAt: data.updatedAt.toISOString(),
});

export const mapStringOrPreviewFilePictureToStringPicture = (
  img: string | PreviewFile
) => {
  if (typeof img === 'string') return img;

  return img.file.name;
};
