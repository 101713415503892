import { FirebaseStorage } from 'firebase/storage';
import React from 'react';
import { useFileRequest } from 'src/administration/contracts/network';

import { FaCropSimple, FaEye, FaTrashCan } from 'react-icons/fa6';
import { PreviewFile } from 'src/types/content';

import './image.css';

interface FirebaseImageProps {
  storageprev?: string;
  img: string | PreviewFile;
  storage: FirebaseStorage;
  withTasks?: boolean;
  isThumbnail?: boolean;
  onChangeToThumbnail?: (img: string | PreviewFile) => void;
  onRemove?: (img: string | PreviewFile) => void;
  onCrop?: (img: string | PreviewFile) => void;
  onImageUrlCreated?: (url: string) => void;
  className?: string;
  imageonly?: boolean;
  canBeThumbnail?: boolean;
}

export const FirebaseImage = ({
  storageprev,
  img,
  storage,
  withTasks,
  isThumbnail,
  onChangeToThumbnail,
  onRemove,
  onCrop,
  onImageUrlCreated,
  className,
  imageonly,
  canBeThumbnail = true,
}: FirebaseImageProps) => {
  const init = React.useRef(false);
  const previous = React.useRef<string | PreviewFile>('');
  const [file, setFile] = React.useState<string>('');
  const [loading, setLoading] = React.useState(true);
  const [fetch] = useFileRequest(storage, img, storageprev);

  React.useEffect(() => {
    if (init.current && img === previous.current) return;
    init.current = true;
    fetch()
      .then((file) => {
        setFile(file);
        onImageUrlCreated && onImageUrlCreated(file);
      })
      .finally(() => setLoading(false));
    previous.current = img;
  }, [img]);

  const handleChangeToThumbnail = () =>
    onChangeToThumbnail && onChangeToThumbnail(img);

  const handleRemove = () => onRemove && onRemove(img);

  const handleCrop = () => onCrop && onCrop(img);

  if (loading)
    return (
      <div className='firebaseimgcontainer'>
        <div className='firebaseimgloader' />
      </div>
    );

  if (imageonly) return <img src={file} alt={file} />;

  return className !== undefined ? (
    <img src={file} className={className} alt={file} />
  ) : (
    <div className='firebaseimgcontainer'>
      <img src={file} className='firebaseimg' alt={file} />
      {withTasks && (
        <div className='firebaseimgtasks'>
          {canBeThumbnail && !isThumbnail && (
            <FaEye
              fontSize={18}
              className='sectionicon'
              color='darkgray'
              onClick={handleChangeToThumbnail}
            />
          )}
          {/* <FaCropSimple
            fontSize={18}
            className='sectionicon'
            color='darkgray'
            onClick={handleCrop}
          /> */}
          <FaTrashCan
            fontSize={18}
            className='sectionicon'
            color='darkgray'
            onClick={handleRemove}
          />
        </div>
      )}
    </div>
  );
};
