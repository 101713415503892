import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDpDVd9QcRK8LIUxuelyg1ytBiZz2mvn9U',
  authDomain: 'btg-wuz-1-ev.firebaseapp.com',
  projectId: 'btg-wuz-1-ev',
  storageBucket: 'btg-wuz-1-ev.appspot.com',
  messagingSenderId: '744224331267',
  appId: '1:744224331267:web:0aeab34b6569b0dc33a036',
  measurementId: 'G-35BQYD8W8G',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const store = getFirestore(app);
export const storage = getStorage();

export const log = (event: string, props: any) => {
  logEvent(analytics, event, props);
};

export const hostingUrl = 'https://www.btg-manching.de';
