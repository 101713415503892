import { Merchandise } from '../../domain/merchandise';

export const generateSizeText = (merchandise: Merchandise) => {
  if (!merchandise.size) return '';

  var pretext = '';
  var sizetext = '';

  if (merchandise.size.length !== '0') {
    pretext += 'L';
    sizetext = merchandise.size.length + 'cm';
  }

  if (merchandise.size.width !== '0') {
    pretext += pretext !== '' ? ' x B' : 'B';
    sizetext =
      sizetext !== ''
        ? sizetext + ' x ' + merchandise.size.width + 'cm'
        : merchandise.size.width + 'cm';
  }

  if (merchandise.size.height !== '0') {
    pretext += pretext !== '' ? ' x H' : 'H';
    sizetext =
      sizetext !== ''
        ? sizetext + ' x ' + merchandise.size.height + 'cm'
        : merchandise.size.height + 'cm';
  }

  if (pretext === '') return '';

  return pretext + ': ' + sizetext;
};
