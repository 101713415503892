import React from 'react';

import './loader.css';

export const PageLoader = () => {
  return (
    <div className='pageloadercontainer'>
      <div className='pageloader'>
        <h1>Loading...</h1>
      </div>
    </div>
  );
};
