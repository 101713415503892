import React from 'react';
import { MerchandiseOverview } from './application';

export const MerchandisePage = () => {
  return (
    <div className='content'>
      <MerchandiseOverview />
    </div>
  );
};
