import { AnalysisEvent } from '../../domain/events';
import { makeAutoObservable } from 'mobx';
import { AnalysisInfo } from '../../domain/info';
import { log } from 'src/configuration';

export class AnalysisService {
  public static instance: AnalysisService = new AnalysisService();

  public timer?: NodeJS.Timeout;
  public info?: AnalysisInfo;
  private infoTimer?: NodeJS.Timeout;

  constructor() {
    makeAutoObservable(this);
  }

  private log = async (
    event: AnalysisEvent,
    msg: any,
    description?: string
  ) => {
    if (event === AnalysisEvent.ERROR) {
      this.info = AnalysisInfo.error(description ?? msg.toString());
      this.timer = setTimeout(this.clearTimer, 5000);
      this.infoTimer = setTimeout(this.clearInfo, 5500);
    }

    log(event, msg);
  };

  private clearTimer = () => {
    clearTimeout(this.timer);
    clearTimeout(this.infoTimer);
    this.infoTimer = undefined;
    this.timer = undefined;
  };

  private clearInfo = () => {
    this.info = undefined;
  };

  public static log = (event: AnalysisEvent, msg: any, description?: string) =>
    this.instance.log(event, msg, description);
}
