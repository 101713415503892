import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { PreviewFile } from 'src/types/content';

export interface FirebaseMerchandise {
  id: string;
  name: string;
  costs: number;
  available: boolean;
  pictures: string[];
  description: string;
  position: number;
  size?: FirebaseSize;
  tags?: string[];
  createdAt: string;
  updatedAt: string;
}

export interface Merchandise {
  id: string;
  name: string;
  costs: string;
  available: boolean;
  pictures: (string | PreviewFile)[];
  description: EditorState;
  position: number;
  size?: Size;
  tags?: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface FirebaseSize {
  length: number;
  width: number;
  height: number;
}

export interface Size {
  length: string;
  width: string;
  height: string;
}

export const DefaultMerchandise: Merchandise = {
  available: true,
  costs: '0.0',
  description: EditorState.createEmpty(),
  id: '0',
  name: '',
  pictures: [],
  position: 0,
  size: {
    height: '0',
    width: '0',
    length: '0',
  },
  tags: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const mapFirebaseMerchToMerch = (
  firebase: FirebaseMerchandise
): Merchandise => ({
  ...firebase,
  description: EditorState.createWithContent(
    convertFromRaw(JSON.parse(firebase.description))
  ),
  size: {
    height: firebase.size?.height.toString() ?? '',
    width: firebase.size?.width.toString() ?? '',
    length: firebase.size?.length.toString() ?? '',
  },
  costs: firebase.costs.toString(),
  createdAt: new Date(firebase.createdAt),
  updatedAt: new Date(firebase.updatedAt),
});

export const mapMerchToFirebaseMerch = (
  merchandise: Merchandise
): FirebaseMerchandise => ({
  ...merchandise,
  description: JSON.stringify(
    convertToRaw(merchandise.description.getCurrentContent())
  ),
  size: {
    height: parseFloat(merchandise.size?.height ?? '0'),
    width: parseFloat(merchandise.size?.width ?? '0'),
    length: parseFloat(merchandise.size?.length ?? '0'),
  },
  costs: parseFloat(merchandise.costs),
  pictures: merchandise.pictures.map(
    mapStringOrPreviewFilePictureToStringPicture
  ),
  createdAt: merchandise.createdAt.toISOString(),
  updatedAt: merchandise.updatedAt.toISOString(),
});

export const mapStringOrPreviewFilePictureToStringPicture = (
  img: string | PreviewFile
) => {
  if (typeof img === 'string') return img;

  return img.file.name;
};
