import React from 'react';
import ReactCrop, { type Crop } from 'react-image-crop';

import { observer } from 'mobx-react-lite';
import { AdminOverviewService } from '../../service/overview';
import * as Components from 'src/administration/components';
import getCroppedImg from '../../helper/helper';

import 'react-image-crop/dist/ReactCrop.css';
import './crop.css';

export const ImageCrop = observer(() => {
  const [crop, setCrop] = React.useState<Crop>();
  const [imgUrl, setImgUrl] = React.useState<string>();
  const [file, setFile] = React.useState<File>();
  const selected = AdminOverviewService.instance.selected;

  if (selected?.type === 'Bookings') return <div />;

  const content = selected?.data;

  const finish = () => {
    setTimeout(() => {
      if (!imgUrl || !crop || !content?.crop) return;
      if (crop.width === 0 || crop.height === 0) {
        dispose();
      } else {
        var cropName =
          typeof content?.crop === 'string'
            ? content?.crop
            : content?.crop.file.name;
        getCroppedImg(imgUrl, crop, cropName).then(setFile);
      }
    }, 250);
  };

  const save = () => {
    if (!content || !file || !content.crop) return false;
    content.updatePictureFromCrop(file, content.crop);
    content.setCrop(undefined);
    dispose();
    return true;
  };

  const cancel = () => {
    if (!content) return false;
    content.setCrop(undefined);
    dispose();
    return true;
  };

  const dispose = () => {
    setCrop(undefined);
    setFile(undefined);
  };

  if (content?.crop) {
    const image = content.crop;
    return (
      <div className='cropcontainer'>
        <div className='cropinner'>
          <div />
          <ReactCrop
            crop={crop}
            onChange={setCrop}
            onDragEnd={finish}
            className='cropimage'
          >
            <Components.FirebaseImage
              storage={AdminOverviewService.instance.storage!}
              img={image}
              storageprev={`${content?.parent?.reduce(
                (prev, curr) => prev + curr + '/',
                ''
              )}${content?.id}`}
              onImageUrlCreated={setImgUrl}
              className='cropinnerimage'
            />
          </ReactCrop>
          <div className='cropactions'>
            <Components.CancelButton
              onCancel={cancel}
              label='Abbrechen'
              className='cropaction'
            />
            <Components.SubmitButton
              onSubmit={save}
              disabled={!file}
              label='Speichern'
              className='cropaction'
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
});
