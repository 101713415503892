import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import './input.css';

interface MultilineInputProps {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (value: string, html: string, valid: boolean) => void;
  errorHint?: string;
  className: string;
  regexp?: RegExp;
  rows?: number;
  readonly?: boolean;
}

export const MultilineInput = ({
  id,
  value,
  onChange,
  placeholder,
  className,
  regexp,
  rows,
  readonly,
}: MultilineInputProps) => {
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (readonly) return;
    const value = event.target.value;
    const error = !regexp?.test(value) ?? false;
    setError(error);
    onChange(value, event.target.innerHTML, !error);
  };

  const handleFocus = () => {
    if (readonly) return;
    setFocused(true);
  };

  React.useEffect(() => {
    const textarea = document.getElementById(id);
    if (!textarea) return;
    textarea.style.height = '160px';
    if (value === '') return;
    textarea.style.height = Math.min(textarea.scrollHeight, 160) + 'px';
  }, [value]);

  return (
    <div className={`inputcontainer ${className}`}>
      <textarea
        id={id}
        value={value}
        onChange={handleChange}
        onFocusCapture={handleFocus}
        placeholder={placeholder}
        className='customtextarea'
        rows={rows}
        readOnly={readonly}
      />
      {focused && (
        <div className='hinticoncontainer multiline'>
          {error ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className='hinticon error'
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='hinticon success'
            />
          )}
        </div>
      )}
    </div>
  );
};
