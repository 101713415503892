import React from 'react';
import { AppStorage } from 'src/Storage';
import * as Storage from 'firebase/storage';
import * as Services from 'src/Services';
import { PreviewFile } from 'src/types/content';

const getPictureUrl = async (pictureRef: Storage.StorageReference) => {
  try {
    return await Storage.getDownloadURL(pictureRef);
  } catch (error: any) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break;
    }
    return '';
  }
};

const downloadFile = async (
  fileRef: Storage.StorageReference,
  name: string
): Promise<File | undefined> => {
  var url = await getPictureUrl(fileRef);

  if (url === '') return;

  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const file = new File([xhr.response], name);
      resolve(file);
    };
    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.open('GET', url);
    xhr.send();
  });
};

export const useFileRequest = (
  storage: Storage.FirebaseStorage,
  file: string | PreviewFile,
  previous?: string
) => {
  const fetcher = React.useCallback((): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      if (typeof file !== 'string') {
        resolve(file.url);
        return;
      }

      var filename = previous ? `${previous}/${file}` : file;

      var stored = AppStorage.getItem<string>(filename);
      if (stored !== null) {
        resolve(stored);
        return;
      }

      try {
        var img: string = '';

        const pictureRef = Storage.ref(storage, filename);
        var download = await downloadFile(pictureRef, filename);
        if (download) {
          var urlCreator = window.URL || window.webkitURL;
          img = urlCreator.createObjectURL(download);
        }

        AppStorage.setItem(filename, img);
        resolve(img);
      } catch (error) {
        Services.AnalysisApi.log(Services.AnalysisEvent.ERROR, {
          error: `useFileRequest: ${filename}, ${error}`,
        });
        reject();
      }
    });
  }, [file, previous, storage]);

  return [fetcher];
};
