import moment from 'moment';
import 'moment/locale/de';
import { Buchung } from '../../domain/buchung';
import { isBeforeDay, isInRange, isSameDay } from './extension';

export class CalendarService {
  el: HTMLDivElement | null;
  events: Buchung[];
  current: moment.Moment;
  header: HTMLDivElement | null = null;
  title: HTMLHeadingElement | null = null;
  oldMonth: HTMLDivElement | null = null;
  month: HTMLDivElement | null = null;
  week: HTMLDivElement | null = null;
  classes: string[] = [];
  next: boolean = false;

  /**
   *
   */
  constructor(selector: string, events: Buchung[]) {
    this.el = document.querySelector<HTMLDivElement>(selector);
    this.events = events;
    this.current = moment().locale('de').date(1);
    this.draw();
  }

  static create = (data: Buchung[]) => {
    var calendar = new CalendarService('#calendar', data);
  };

  draw = () => {
    //Create Header
    this.drawHeader();

    //Draw Month
    this.drawMonth();

    this.drawLegend();
  };

  drawHeader = () => {
    var self = this;
    if (!this.header) {
      //Create the header elements
      this.header = this.createElement('div', 'header');

      this.title = this.createElement('h3');

      var right = this.createElement('div', 'right');
      right.addEventListener('click', function () {
        self.nextMonth();
      });

      var left = this.createElement('div', 'left');
      left.addEventListener('click', function () {
        self.prevMonth();
      });

      //Append the Elements
      this.header.appendChild(this.title);
      this.header.appendChild(right);
      this.header.appendChild(left);
      this.el?.appendChild(this.header);
    }

    if (!this.title) return;
    this.title.innerHTML = this.current.format('MMMM YYYY');
  };

  drawMonth = () => {
    var self = this;

    if (this.month !== null) {
      this.oldMonth = this.month;
      this.oldMonth.className = 'month out ' + (self.next ? 'next' : 'prev');
      self.oldMonth?.parentNode?.removeChild(self.oldMonth);
      self.month = self.createElement('div', 'month');
      self.backFill();
      self.currentMonth();
      self.fowardFill();
      self.el?.appendChild(self.month);
      self.month.className = 'month in ' + (self.next ? 'next' : 'prev');
    } else {
      this.month = this.createElement('div', 'month');
      this.el?.appendChild(this.month);
      this.backFill();
      this.currentMonth();
      this.fowardFill();
      this.month.className = 'month new';
    }
  };

  backFill = () => {
    var clone = this.current.clone();
    var dayOfWeek = clone.day();

    if (!dayOfWeek) {
      return;
    }

    clone.subtract(dayOfWeek + 1, 'days');

    for (var i = dayOfWeek; i > 0; i--) {
      this.drawDay(clone.add(1, 'days'));
    }
  };

  fowardFill = () => {
    var clone = this.current.clone().add(1, 'months').subtract(1, 'days');
    var dayOfWeek = clone.day();

    if (dayOfWeek === 6) {
      return;
    }

    for (var i = dayOfWeek; i < 6; i++) {
      this.drawDay(clone.add(1, 'days'));
    }
  };

  currentMonth = () => {
    var clone = this.current.clone();

    while (clone.month() === this.current.month()) {
      this.drawDay(clone);
      clone.add(1, 'days');
    }
  };

  getWeek = (day: moment.Moment) => {
    if (!this.week || day.day() === 0) {
      this.week = this.createElement('div', 'week');
      this.month?.appendChild(this.week);
    }
  };

  drawDay = (day: moment.Moment) => {
    this.getWeek(day);

    //Outer Day
    var outer = this.createElement('div', this.getDayClass(day));

    //Day Name
    var name = this.createElement('p', 'day-name c4', day.format('dd'));

    //Day Number
    var number = this.createElement('p', 'day-number c2', day.format('DD'));

    //Events
    this.drawEvents(day, number);

    outer.appendChild(name);
    outer.appendChild(number);
    this.week?.appendChild(outer);
  };

  drawEvents = (day: moment.Moment, element: HTMLElement) => {
    const today = moment();
    if (isBeforeDay(day, today)) {
      element.className += ' previousDays';
    } else if (day.month() === this.current.month()) {
      var todaysEvent = this.events.find((event) =>
        isInRange(day, event.start, event.end)
      );

      if (todaysEvent) {
        if (
          todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstRedSecondRed';
        } else if (
          todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          !todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstRedSecondGreen';
        } else if (
          !todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstGreenSecondRed';
        } else if (
          !todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          !isSameDay(day, todaysEvent.end)
        ) {
          element.className += ' firstGreenSecondRed';
        } else if (
          !isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          !todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstRedSecondGreen';
        } else if (
          !isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstRedSecondRed';
        } else if (
          !todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          !isSameDay(day, todaysEvent.end)
        ) {
          element.className += ' firstGreenSecondRed';
        } else if (
          !todaysEvent.startsAtFirstHalf &&
          isSameDay(day, todaysEvent.start) &&
          isSameDay(day, todaysEvent.end) &&
          !todaysEvent.endsAtSecondHalf
        ) {
          element.className += ' firstGreenSecondGreen';
        } else if (isInRange(day, todaysEvent.start, todaysEvent.end)) {
          element.className += ' firstRedSecondRed';
        } else {
          element.className += ' firstGreenSecondGreen';
        }
      } else {
        element.className += ' firstGreenSecondGreen';
      }
    }
  };

  getDayClass = (day: moment.Moment) => {
    const today = moment();
    this.classes = ['day'];
    if (day.month() !== this.current.month()) {
      this.classes.push('other');
    } else if (today.isSame(day, 'day')) {
      this.classes.push('today');
    }
    return this.classes.join(' ');
  };

  drawLegend = () => {
    var self = this;
    var legend = this.createElement('p', 'legend c3');
    ['Belegt|red', 'Frei|green'].forEach(function (e: string) {
      var parts = e.split('|');
      var entry = self.createElement('span', 'entry ' + parts[1], parts[0]);
      legend.appendChild(entry);
    });
    this.el?.appendChild(legend);
  };

  nextMonth = () => {
    this.current.add(1, 'months');
    this.next = true;
    this.draw();
  };

  prevMonth = () => {
    this.current.subtract(1, 'months');
    this.next = false;
    this.draw();
  };

  createElement = <K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    className?: any,
    innerText?: any
  ) => {
    var ele = document.createElement(tagName);
    if (className) {
      ele.className = className;
    }
    if (innerText) {
      ele.innerText = ele.textContent = innerText;
    }
    return ele;
  };
}
