import React from 'react';

import * as Components from 'src/Components';
import { DefaultMerchandise, Merchandise } from '../../domain/merchandise';
import { getMerchandise } from '../network/read';
import { Merch } from './merch';

import './components.css';

export const MerchandiseOverview = () => {
  const init = React.useRef(false);
  const [merchandise, setMerchandise] = React.useState<Merchandise[]>([
    DefaultMerchandise,
    DefaultMerchandise,
  ]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (init.current) return;
    init.current = true;
    getMerchandise().then((merch) => {
      setMerchandise(merch);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h2
        className={
          merchandise.length === 0
            ? 'merchandiseplaceholder show'
            : 'merchandiseplaceholder'
        }
      >
        Bisher ist kein Merchandise bei uns vorhanden.
      </h2>
      <div className='withinfo'>
        <Components.InfoBar
          type='info'
          message='Derzeit ist unser Merchandise nur über unser Vereinsheim beziehbar!'
        />
        <div className='merchandise'>
          {merchandise.map((merch, index) => (
            <Merch
              key={`merchandise-${index}`}
              merchandise={merch}
              loading={loading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
