import React from 'react';

import { CalendarService } from './cal_service';
import { getBookings } from '../network/read';
import { Buchung } from '../../domain/buchung';
import Skeleton from 'react-loading-skeleton';

import './room.css';

export const Calendar = () => {
  const [loading, setLoading] = React.useState(true);
  const [bookings, setBookings] = React.useState<Buchung[]>([]);

  React.useEffect(() => {
    getBookings()
      .then(setBookings)
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (loading) return;
    CalendarService.create(bookings);
  }, [loading]);

  return loading ? (
    <div className='calendarloadercontainer'>
      <Skeleton className='calendarloader' />
    </div>
  ) : (
    <div id='calendar' />
  );
};
