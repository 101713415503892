import React from 'react';

import './index.css';
import { observer } from 'mobx-react-lite';
import { AnalysisService } from './application';
import { AnalysisInfoLevel } from './domain/info';

export const AnalysisUI = observer(() => {
  const visible = React.useMemo(
    () => AnalysisService.instance.timer !== undefined,
    [AnalysisService.instance.timer]
  );

  const className = React.useMemo(() => {
    if (!AnalysisService.instance.info) {
      return 'analysis';
    }

    switch (AnalysisService.instance.info.level) {
      case AnalysisInfoLevel.Error:
        return 'analysis error';
      case AnalysisInfoLevel.Success:
        return 'analysis success';
      default:
        return 'analysis';
    }
  }, [AnalysisService.instance.info]);

  return (
    <div className={visible ? 'analysisui visible' : 'analysisui hidden'}>
      <div className={className}>
        <label>{AnalysisService.instance.info?.message}</label>
      </div>
    </div>
  );
});
