import React from 'react';
import * as Application from './application';

import './index.css';

type Props = {};

export const RequestPage = (props: Props) => {
  return (
    <div className='content requests'>
      <h2>Anträge</h2>
      <Application.MemberRequest />
      <Application.VereinsraumRequest />
    </div>
  );
};
