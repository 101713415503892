import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SmallArticle } from 'src/Components/SmallArticle';
import { DefaultPreviewContent, PreviewContent } from 'src/types/content';
import { getHappenings } from './read';

interface UpcomingProps {
  maxHeight: number;
}

export const Upcoming = ({ maxHeight }: UpcomingProps) => {
  const navigate = useNavigate();
  const [content, setContent] = React.useState<PreviewContent[]>([
    DefaultPreviewContent,
    DefaultPreviewContent,
    DefaultPreviewContent,
    DefaultPreviewContent,
  ]);
  const [loading, setLoading] = React.useState(true);
  const init = React.useRef(false);

  React.useEffect(() => {
    if (!init.current) {
      init.current = true;
      getHappenings().then((response) => {
        setContent(response as PreviewContent[]);
        setLoading(false);
      });
    }
  }, []);

  const handleClick = (content: PreviewContent) => {
    navigate(`/Veranstaltungen/${content.header.replace(/ /g, '_')}`);
  };

  return (
    <aside className='upcomingcontainer'>
      <h2 className='upcomingtitle'>Kommende Veranstaltungen</h2>
      <div className='upcomings' style={{ height: maxHeight - 68 }}>
        {content.map((cont, i) => (
          <SmallArticle
            key={cont.header + i}
            caller='Veranstaltung'
            content={cont}
            numerator={i}
            loading={loading}
            onClick={handleClick}
          />
        ))}
      </div>
    </aside>
  );
};
