import React from 'react';

import * as Application from 'src/administration/modules/dashboard/application';

import './ui.css';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { AdminOverviewService } from './application/service/overview';

interface DashboardProps {
  firestore: Firestore;
  storage: FirebaseStorage;
}

export const Dashboard = ({ firestore, storage }: DashboardProps) => {
  React.useEffect(() => {
    AdminOverviewService.initialize(firestore, storage);
  }, []);

  return (
    <div className='adminscreen'>
      <Application.Navigation />
      <Application.Editable />
    </div>
  );
};
