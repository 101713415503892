import React, { useEffect, useRef, useState } from 'react';
import { DefaultPreviewContent, PreviewContent } from '../../types/content';
import { SmallArticle } from '../../Components/SmallArticle';
import { useNavigate } from 'react-router-dom';
import { getHappenings } from './application';

export const Happenings = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState<PreviewContent[]>([
    DefaultPreviewContent,
    DefaultPreviewContent,
    DefaultPreviewContent,
    DefaultPreviewContent,
  ]);
  const [loading, setLoading] = useState(true);
  const init = useRef(false);

  useEffect(() => {
    if (!init.current)
      getHappenings().then((response) => {
        setContent(response as PreviewContent[]);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    init.current = true;
  }, []);

  const handleClick = (content: PreviewContent) => {
    navigate(`/Veranstaltungen/${content.header.replace(/ /g, '_')}`);
  };

  return (
    <div className='smallArticleContainer content'>
      {content.map((cont, i) => (
        <SmallArticle
          key={cont.header + i}
          caller='Veranstaltung'
          content={cont}
          numerator={i}
          loading={loading}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};
