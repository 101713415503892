import React from 'react';

import './index.css';

interface ButtonProps {
  id?: string;
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
  className?: string;
  inverted?: boolean;
  withLeftElement?: JSX.Element;
}

export const Button = ({
  id,
  label,
  onClick,
  disabled,
  href,
  className,
  inverted,
  withLeftElement,
}: ButtonProps) => {
  const handleClick = () => {
    if (disabled) return;
    if (onClick) onClick();
  };

  const invertedClassName = inverted ? 'inverted' : '';

  return href ? (
    <a href={href} onClick={handleClick} id={id}>
      <button
        className={
          disabled
            ? `commonbutton disabled ${className} ${invertedClassName} c1`
            : `commonbutton ${className} ${invertedClassName} c1`
        }
      >
        {withLeftElement}
        {label}
      </button>
    </a>
  ) : (
    <button
      id={id}
      onClick={handleClick}
      className={
        disabled
          ? `commonbutton disabled ${className} ${invertedClassName} c1`
          : `commonbutton ${className} ${invertedClassName} c1`
      }
    >
      {withLeftElement}
      {label}
    </button>
  );
};
